import styled from 'styled-components';

import { AccountNumber } from '~/ui/kit/atoms/AccountNumber';
import { baseFont, fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${baseFont};
  
  ${fontSize};

  gap: ${gaps.xxs / 2}px;
  
`;

export const AccountName = styled.div`
  display: flex;
  align-items: center;
  color: var(--eko-primary-color);
  font-weight: bold;
`;

export const Number = styled(AccountNumber)`
  margin-left: ${gaps.s}px;
`;
export const InfoContainer = styled.div`
  display: flex;
  color: var(--eko-third-color);
  font-weight: normal;
  line-height: 100%;
`;

export const AccountType = styled.div`
  text-align: left;
  &:not(:last-child) {
    padding-right: 4px;
    border-right: 1px solid currentColor;
    margin-right: 4px;
  }
`;

export const Balance = styled.div`
  color: var(--eko-third-color);
  text-align: right;
`;

export const ConnectedBadge = styled.div.attrs({ $fs: 'xs' })`
  background-color: var(--eko-primary-color-light-2);
  border: 1px solid var(--eko-primary-color);
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin-left: ${gaps.xxs}px;
  padding: ${gaps.xxs / 2}px ${gaps.xxs}px;
  border-radius: 4px;
`;
