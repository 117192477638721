import { String } from './String.styled';
import { Number } from './Number.styled';
import { Date } from './Date.styled';
import { RadioGroup } from './Radio.styled';
import { Select } from './Select';
import { Amount } from './Amount';
import { Checkbox } from './Checkbox';
import { Phone } from './Phone';

export const Input = {
  Amount,
  String,
  Number,
  Date,
  RadioGroup,
  Select,
  Checkbox,
  Phone,
};

export * from './Input.types';
