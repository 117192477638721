import { List } from 'antd';
import styled from 'styled-components';

import { baseFont, fontSize } from '~/ui/kit/constants/fonts';
import { focusOutline } from '~/ui/kit/constants/focusOutline';

export const Container = styled(List.Item)`
  &.ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border-block-end: none;
    ${baseFont};
  }
`;

export const ClickWrapper = styled.button.attrs({ type: 'button' })`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background: none;
  ${focusOutline};
`;

export const Content = styled.div.attrs({ $fs: 's' })`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
  width: 100%;
  ${fontSize};
`;

export const Subtitle = styled.div.attrs({ $fs: 'xs' })`
  margin-top: 10px;
  color: var(--eko-third-color);
  font-weight: 500;
  ${fontSize};
  
  &:only-child {
    margin-top: 0;
  }
`;
