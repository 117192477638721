import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonProps } from './Button.atom.types';
import * as Styled from './Button.styled';

export const Button: React.FC<ButtonProps> = (({ br, isLoading, link, noDecoration, ...props }) => {
  const history = useHistory();
  const $br = useMemo(() => {
    if (!props.size) {
      return br;
    }

    switch (props.size) {
      case 'small':
        return 's';
      case 'middle':
        return 'm';
      case 'large':
        return 'l';
    }
  }, [br, props.size]);

  const loadingContent = useMemo(() => (
    <>
      <Styled.Spinner />
    </>
  ), []);

  const onClick = useCallback((e: any) => {
    props.onClick && props.onClick(e);

    if (link) {
      history.push(link);
    }
  }, [props.onClick, link]);

  return (
    <Styled.Container
      {...props}
      $noDecoration={noDecoration}
      $href={link}
      onClick={onClick}
      $br={$br}
      $fs={props.fs}
    >
      {isLoading ? loadingContent : props.children}
    </Styled.Container>
  );
});
