import { css } from 'styled-components';

export type FontProps = {
  $ff?: string;
  $fw?: number
}

export const font = css<FontProps>`
  ${({ $ff }) => $ff && css`
    font-family: ${$ff};
  `};

  ${({ $fw }) => $fw && css`
    font-weight: ${$fw};
  `};
`;
