import React from 'react';

export const IconArrow: React.FC<{ className?: string }> = (props) => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M18 15.5008C17.8684 15.5016 17.7379 15.4763 17.6161 15.4266C17.4943 15.3768 17.3834 15.3035 17.29 15.2108L12.71 10.6208C12.617 10.5271 12.5064 10.4527 12.3846 10.4019C12.2627 10.3511 12.132 10.325 12 10.325C11.868 10.325 11.7373 10.3511 11.6154 10.4019C11.4936 10.4527 11.383 10.5271 11.29 10.6208L6.71 15.2108C6.5217 15.3991 6.2663 15.5049 6 15.5049C5.7337 15.5049 5.47831 15.3991 5.29 15.2108C5.1017 15.0225 4.99591 14.7671 4.99591 14.5008C4.99591 14.3689 5.02188 14.2384 5.07234 14.1165C5.1228 13.9947 5.19676 13.884 5.29 13.7908L9.88 9.2108C10.4499 8.66323 11.2096 8.35742 12 8.35742C12.7904 8.35742 13.5501 8.66323 14.12 9.2108L18.71 13.7908C18.8037 13.8838 18.8781 13.9944 18.9289 14.1162C18.9797 14.2381 19.0058 14.3688 19.0058 14.5008C19.0058 14.6328 18.9797 14.7635 18.9289 14.8854C18.8781 15.0072 18.8037 15.1178 18.71 15.2108C18.6166 15.3035 18.5057 15.3768 18.3839 15.4266C18.2621 15.4763 18.1316 15.5016 18 15.5008Z"
        fill="currentColor"/>
    </svg>
  );
};
