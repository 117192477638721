import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'antd';
import cn from 'classnames';

import { getFontSize } from '~/ui/kit/constants/fonts';
import { Input } from '~/ui/kit/molecules/Input';
import { media } from '~/ui/utils/media';

import { gaps } from '~/ui/kit/constants/sizes';
import { Button, ButtonProps } from '~/ui/kit/atoms/Button';

export const Container = styled(Form)`
  background: white;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gaps.xl}px;
  margin-bottom: ${gaps.xl}px;
  
  ${media.md`
    gap: ${gaps.xxl}px;
  `}
`;

export const DateInput = styled(Input.Date).attrs({
  format: 'MM.DD.YYYY',
})`
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gaps.s}px;
`;

export const Error = styled.div`
  margin-top: ${gaps.xxs}px;
  text-align: center;
  color: var(--eko-error-color);
`;

export const ItemContainer = styled.div`
  &:last-child {
    margin: 0;
  }
`;

const labelStyles = css`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  ${getFontSize('xs')};
  font-weight: bold;

  ${media.lg`
    font-size: 16px;
  `}

  & > * {
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const Label = styled.span``;

export const LabelDescription = styled.span`
  font-size: 14px;
  color: var(--eko-third-color);
  text-align: left;
  margin-top: 12px;
  font-weight: normal;
`;

export const LabelContainer = styled.span`
  ${labelStyles};
`;

export const Item = styled(Form.Item)`
  margin-bottom: 0;
`;

export const Description = styled.div`
  font-size: 14px;
  color: var(--eko-third-color);
  text-align: left;
  margin-top: 12px;
`;

export const CompositeItemContainer = styled.div.attrs({
  className: cn(['ant-row', 'ant-form-item-row'])
})`
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  ${LabelContainer} {
    font-weight: 400;
    color: var(--eko-third-color);
  }
`;

export const CompositeLabelContainer = styled.span.attrs({ className: cn('ant-col', 'ant-form-item-label') })`
  ${labelStyles};
  flex-direction: column;
  gap: 8px;
  &.ant-form-item-label {
    padding: 0;
  }
`;

export const ActionItem = styled(Form.Item)`
  margin: 0;
`;

export const SubmitAction = styled(ActionItem)`
  width: 100%;
`;

export const ActionButton = styled(Button).attrs({ type: 'primary', br: 'm' })`
  width: 100%;
  color: white;
  min-height: 48px;
  font-weight: bold;
  font-size: 14px;
  font-family: Mulish, sans-serif;
  
  ${media.sm`
    font-size: 16px;
  `}
  
  ${media.xl`
    font-size: 18px;
  `}
`;

export const CancelButton = styled(ActionButton).attrs({ ghost: true })``;

export const SubmitButton = styled(ActionButton)`
  ${({ disabled }) => disabled && css`
    &.ant-btn {
      &, &:hover {
        background: var(--eko-fifth-color);
        color: var(--eko-third-color);
      }
    }
  `};

  ${media.md`
    width: auto;
  `}
` as React.FC<ButtonProps>;
