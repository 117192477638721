import React, { useMemo } from 'react';

import * as S from './Menu.skeleton.styled';

type Props = {
  skeletonItemsCount: number
}
export const MenuSkeleton: React.FC<Props> = (props) => {
  const skeletons = useMemo(() =>
    (new Array(props.skeletonItemsCount))
      .fill(null)
      .map((_, index) => <S.MenuSkeletonItem key={index} />),
  [props.skeletonItemsCount]
  );

  return (
    <S.Container>{skeletons}</S.Container>
  );
};
