import { Checkbox } from 'antd';
import styled from 'styled-components';
import { baseFont } from '~/ui/kit/constants/fonts';

export const Container = styled(Checkbox)`
  display: flex;
  align-items: center;
  --checkbox-molecule-size: 20px;
  --checkbox-molecule-border-radius: 2px;
  --checkbox-molecule-border-width: 2px;
  --checkbox-molecule-color: var(--eko-primary-color);
  --checkbox-molecule-outline-color: var(--eko-primary-color-light);
  ${baseFont};

  & .ant-checkbox {
    top: 0;
  }
  
  & .ant-checkbox .ant-checkbox-inner {
    width: var(--checkbox-molecule-size);
    height: var(--checkbox-molecule-size);
    border-width: var(--checkbox-molecule-border-width);
    border-radius: var(--checkbox-molecule-border-radius);

    &:after {
      border-color: var(--checkbox-molecule-color);
      border-width: 2.5px;
      width: calc(var(--checkbox-molecule-size) * 0.4);
      height: calc(var(--checkbox-molecule-size) * 0.65);
      inset-inline-start: 16.5%;
    }
  }

  & .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: unset;
      border-color: var(--checkbox-molecule-color);
    }

    &:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
      background-color: unset;
    }

    &:after {
      border-color: var(--checkbox-molecule-color);
      border-radius: var(--checkbox-molecule-border-radius);
    }
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
    .ant-checkbox-inner {
      border-color: var(--checkbox-molecule-color);
    }

    .ant-checkbox-checked:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        background-color: unset;
      }

      &:after {
        border-color: var(--checkbox-molecule-color);
      }
    }
  }

  & .ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
    outline-color: var(--checkbox-molecule-outline-color);
  }
`;
