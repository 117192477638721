import React from 'react';

import * as Styled from './AccountNumber.atom.styled';

interface Props {
  number: string;
  className?: string;
}

export const AccountNumber: React.FC<Props> = ({ number, className }) => {
  const digits = number.length >= 4
    ? number.substring(number.length - 4)
    : '';

  return (
    <Styled.Container className={className}>
      {`•••• ${digits}`}
    </Styled.Container>
  );
};
