import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import classes from 'classnames';
import { pipe } from 'fp-ts/function';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';

import s from './SideBarLayout.module.css';

import { sideBar as sbClasses } from '~/constants/customization';

import LeftMenu from '@components/LeftMenu/LeftMenu';
import TheHeader from '@components/layout/header';
import Notifications from '@components/layout/notification/Notifications';

import * as authActions from '@store/authentication/actions';
import { selectMenuType, selectNotifications } from '@store/application/selectors';

import { getHasAlpacaAccount } from '@store/profile/selectors';
import { LegalDisclaimerPopup } from '~/widgets/LegalDisclaimerPopup';

import * as S from './Sidebar.styled';
import { organizationSelector } from '@store/organization/selectors';
import { profileSettingsSelector } from '@store/settings';

const Wrapper = ({ useContainer, children }) => {
  if (useContainer) {
    return (
      <div className={classes('container')}>
        {children}
      </div>
    );
  }
  return children;
};

export default function SideBarLayout ({ children }) {
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const userSettings = useSelector(profileSettingsSelector);

  const menuType = useSelector(selectMenuType);
  const notifications = useSelector(selectNotifications);

  const hasAlpacaAccount = getHasAlpacaAccount();
  const showSidebar = menuType === 'vertical';

  const handleLogout = () => dispatch(authActions.logout());

  const showDisclaimer = useMemo(() => {
    const startedWithAlpacaOnboarding = window.sessionStorage.getItem('eko-starts-alpaca-onboarding');
    if (startedWithAlpacaOnboarding === 'true' || startedWithAlpacaOnboarding === true) {
      return false;
    };

    const showInitialDisclaimer = pipe(
      userSettings,
      RD.map(settings => !settings.dontShowInitialDisclaimer),
      RD.fold(
        () => false,
        () => false,
        () => true,
        (value) => value,
      )
    );

    const hideInitialDisclaimerDate = pipe(
      userSettings,
      RD.map((settings) => settings.hideInitialDisclaimerDate),
      RD.toUndefined
    );

    if (!RD.isPending(userSettings)
      && organization
      && organization.organizationInfo
      && organization.organizationInfo.show_initial_disclaimer !== undefined
      && organization.organizationInfo.show_initial_disclaimer === true
    ) {
      if (organization.organizationInfo.disclaimer_expiration_days_number === 0) {
        return true;
      }

      if (hideInitialDisclaimerDate && organization.organizationInfo.disclaimer_expiration_days_number !== undefined) {
        return isAfter(
          new Date(),
          addDays(
            hideInitialDisclaimerDate,
            organization.organizationInfo.disclaimer_expiration_days_number
          )
        );
      }

      return showInitialDisclaimer;
    }

    return false;
  }, [organization, userSettings]);

  return (
    <>
      {showDisclaimer && <LegalDisclaimerPopup/>}
      <S.Container className={classes(
        s.layout,
        !showSidebar && s.layout_fullWidth,
      )}>
        <TheHeader
          type={showSidebar ? 'general' : 'vendor'}
          className={classes(showSidebar && s.layout__header_content)}
        />

        {showSidebar && (
          <LeftMenu
            handleLogout={handleLogout}
            hasAlpacaAccount={hasAlpacaAccount}
          />
        )}

        <Wrapper useContainer={!showSidebar}>
          <div className={classes(
            s['layout__content'],
            sbClasses.content,
          )}>
            {!!notifications.length && (
              <Notifications
                notifications={notifications}
                fullScreen={showSidebar}
              />
            )}

            {children}
          </div>
        </Wrapper>
      </S.Container>
    </>
  );
}
