import { css } from 'styled-components';

export const focusOutline = css<{$outlineColor?: string}>`
  &:focus-visible {
    --eko-button-outline-color: ${({ $outlineColor }) => $outlineColor || 'var(--eko-primary-color-light)'};
    outline-color: ${({ $outlineColor }) => $outlineColor || 'var(--eko-primary-color-light)'};
    outline-style: solid;
    outline-width: 4px;
    outline-offset: 1px;
  }
`;
