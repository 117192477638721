import React, { useCallback, useEffect, useState, createRef, forwardRef, useImperativeHandle } from "react";
import { useDropzone } from "react-dropzone";
import { default as PhoneInputComponent } from 'react-phone-input-2';

import { countryList } from '~/utils/location';
import forwardWhiteArrow from '~/assets/images/forward-white-arrow.png';
import backWhiteArrow from '~/assets/images/back-white-arrow.png';
import enterIcon from '~/assets/images/enterIcon.png';
import uploadIcon from '~/assets/images/uploadIcon.png';
import infoIcon from '~/assets/images/infoIcon.png';
import CrossPopoverIcon from '~/assets/images/crossIconPopover.png';
import EditIcon from '~/assets/images/edit.svg';
import DownArrowDarkGrayIcon from '~/assets/images/downArrowDarkGray.png';

import styles from './OnboardingAlpaca.module.css';
import {
  fundingSources,
  isUSCitizenOptions,
  fieldNames,
  visaTypes,
  incomeOptions,
  employmentOptions,
  isValidSSN,
  isValidZipCode,
  slideNames,
} from "../../../utils/alpacaUtils";
import Loader from "../../components/Loader/Loader";
import Checkbox from "../../components/Checkbox/Checkbox";
import SelectDropdown from "../../components/SelectInput/SelectInput";
import moment from "moment";
import { useSelector } from 'react-redux';
import { selectIsOrganizationLoading, selectOrganization } from '@store/organization/selectors';
import { isVendor } from '@services/application';
import { every, some } from 'lodash';
import { NonVendorAgreement } from "@pages/onboarding_alpaca/components/NonVendorAgreement";
import { VendorAgreement } from "@pages/onboarding_alpaca/components/VendorAgreement";
import classes from 'classnames';

export function SlideContainer({
  isNextPreviousButtonDisabled,
  id,
  isFirstItem,
  children,
  onClickPreviousBtn,
  onClickNextBtn,
  title,
  isNavigatedFromConfirmation,
}) {
  const getOpacity = () => {
    if (isFirstItem || isNavigatedFromConfirmation) {
      return 0;
    } else if (isNextPreviousButtonDisabled) {
      return 0.5;
    } else {
      return 1;
    }
  };

  return (
    <div id={id} className={styles.slideContainer}>
      <div>
        <h2 className={styles.formFieldLabel}>{title}</h2>
        <div className={styles.inputBox}>
          <div className={styles.inputContainer}>{children}</div>
        </div>
        <div className={styles.btnsBar}>
          <button
            tabIndex="-1"
            disabled={
              isFirstItem ||
              isNextPreviousButtonDisabled ||
              isNavigatedFromConfirmation
            }
            style={{ opacity: getOpacity() }}
            onClick={onClickPreviousBtn}
          >
            <img className={styles.arrowIcon} src={backWhiteArrow} />
            <div>Previous</div>
          </button>
          <button
            tabIndex="-1"
            style={{ opacity: isNextPreviousButtonDisabled ? 0.5 : 1 }}
            disabled={isNextPreviousButtonDisabled}
            onClick={onClickNextBtn}
          >
            <div>
              {isNavigatedFromConfirmation ? "Save and go back" : "Next"}
            </div>
            <img className={styles.arrowIcon} src={forwardWhiteArrow} alt="" />
          </button>
        </div>
        <div className={styles.enterBtn}>
          <span>Press Enter</span>
          <img src={enterIcon} alt="" />
        </div>
      </div>
    </div>
  );
}

export function SlideContainerKYC({
  isNextPreviousButtonDisabled,
  id,
  isFirstItem,
  children,
  onClickPreviousBtn,
  onClickNextBtn,
  title,
  isNavigatedFromConfirmation,
  isLastItem,
  isLoading,
}) {
  const getOpacity = () => {
    if (isFirstItem || isNavigatedFromConfirmation) {
      return 0;
    } else if (isNextPreviousButtonDisabled) {
      return 0.5;
    } else {
      return 1;
    }
  };

  return (
    <div id={id} className={styles.slideContainerKYC}>
      <div>
        <h2 className={styles.formFieldLabel}>{title}</h2>
        <div className={styles.inputBoxKYC}>
          <div className={styles.inputContainer}>{children}</div>
        </div>
        <div className={styles.btnsBar}>
          <button
            tabIndex="-1"
            disabled={
              isFirstItem ||
              isNextPreviousButtonDisabled ||
              isNavigatedFromConfirmation
            }
            style={{ opacity: getOpacity() }}
            onClick={onClickPreviousBtn}
          >
            <img className={styles.arrowIcon} src={backWhiteArrow} />
            <div>Previous</div>
          </button>
          <button
            tabIndex="-1"
            style={{ opacity: isNextPreviousButtonDisabled ? 0.5 : 1 }}
            disabled={isNextPreviousButtonDisabled || isLoading}
            onClick={onClickNextBtn}
          >
            {isLoading ? (
              <Loader customStyle={{ width: "20px", height: "20px" }} />
            ) : (
              <>
                <div>{isLastItem ? "Done" : "Next"}</div>
                <img
                  className={styles.arrowIcon}
                  src={forwardWhiteArrow}
                  alt=""
                />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export function DocumentSection({
  isNextPreviousButtonDisabled,
  value,
  onChange,
  onClickPreviousBtn,
  onClickNextBtn,
  fileInfoValue,
  setFileInfoValue,
  refvalue,
  isNavigatedFromConfirmation,
  onClickSaveAndGoBack,
}) {
  // const [fileInfo, setFileInfo] = useState(fileInfoValue);
  const maximumSize = 20;
  const [error, setError] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size / 1000000 > maximumSize) {
      setError("File size should be less then 20MB");
    } else {
      setError(false);
      setFileInfoValue(acceptedFiles[0]);
      getBase64(acceptedFiles[0]).then((res) => {
        console.log("res", res);
        // onChange({ base64: res.split(",")[1], type: acceptedFiles[0].type });
      });
    }
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleClickNextBtn = () => {
    if (value) {
      if (isNavigatedFromConfirmation) {
        onClickSaveAndGoBack();
      } else {
        onClickNextBtn();
      }
    } else {
      setError("Required");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <SlideContainer
      title="Submit your identification document"
      onClickNextBtn={handleClickNextBtn}
      onClickPreviousBtn={onClickPreviousBtn}
      isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
      id="DocumentSection"
      isNavigatedFromConfirmation={isNavigatedFromConfirmation}
    >
      <React.Fragment>
        <React.Fragment>
          <div className={styles.attachLabel}>Attach Documents</div>
          <div
            tabIndex="-1"
            {...getRootProps()}
            className={styles.fileUploadComp}
            style={{ borderColor: isDragActive ? "var(--eko-primary-color)" : "" }}
            // onKeyUp={(e) => onPressEnter(e, handleClickNextBtn)}
          >
            <input
              type="text"
              className={styles.fileInput}
              ref={refvalue}
              tabIndex="-1"
            />
            <input
              {...getInputProps()}
              className={styles.fileInput}
              accept=".png, .jpeg, .pdf"
              tabIndex="-1"
            />
            {fileInfoValue ? (
              <div>{fileInfoValue.name}</div>
            ) : (
              <React.Fragment>
                <img src={uploadIcon} alt="" />
                <label className="button" htmlFor="fileElem">
                  {isDragActive ? (
                    "Drop files here..."
                  ) : (
                    <>
                      Drag and Drop or{" "}
                      <span
                        style={{
                          color: "var(--eko-primary-color)",
                          textDecoration: "underline",
                        }}
                      >
                        browse
                      </span>{" "}
                      your documents
                    </>
                  )}
                </label>
              </React.Fragment>
            )}
          </div>
          <div className={styles.uploadRequirements}>
            Accepted file type: png, pdf, jpeg. Size 20 MB max
          </div>
          {error && <div className={styles.inputErrorMsg}>{error}</div>}
        </React.Fragment>
      </React.Fragment>
    </SlideContainer>
  );
}

export function UploadInput({ label, onChange }) {
  const maximumSize = 20;
  const [error, setError] = useState(null);
  const [fileInfoValue, setFileInfoValue] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size / 1000000 > maximumSize) {
      setError("File size should be less then 20MB");
    } else {
      setError(false);
      setFileInfoValue(acceptedFiles[0]);
      getBase64(acceptedFiles[0]).then((res) => {
        onChange({ base64: res.split(",")[1], type: acceptedFiles[0].type });
      });
    }
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onClickCross = (e) => {
    e.stopPropagation();
    setFileInfoValue(null);
    onChange(null);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <div className={styles.attachLabel}>{label}</div>
        <div
          {...getRootProps()}
          className={styles.fileUploadComp}
          style={{ borderColor: isDragActive ? "var(--eko-primary-color)" : "" }}
          tabIndex="-1"
        >
          <input type="text" className={styles.fileInput} tabIndex="-1" />
          <input
            {...getInputProps()}
            className={styles.fileInput}
            accept=".png, .jpeg, .pdf"
            tabIndex="-1"
          />
          {fileInfoValue ? (
            <div className="d-flex align-items-center">
              <span style={{ width: "200px", wordBreak: "break-word" }}>
                {fileInfoValue.name}{" "}
              </span>
              <button
                tabIndex="-1"
                onClick={onClickCross}
                className={styles.crossBtn}
              >
                <img src={CrossPopoverIcon} alt="" />
              </button>
            </div>
          ) : (
            <React.Fragment>
              <img src={uploadIcon} alt="" />
              <label className="button" htmlFor="fileElem">
                {isDragActive ? (
                  "Drop files here..."
                ) : (
                  <>
                    <span
                      style={{
                        color: "var(--eko-primary-color)",
                        textDecoration: "underline",
                      }}
                    >
                      Browse
                    </span>{" "}
                    your documents
                  </>
                )}
              </label>
            </React.Fragment>
          )}
        </div>
        <div className={styles.uploadRequirements}>
          Accepted file type: png, pdf, jpeg. Size 20 MB max
        </div>
        {error && <div className={styles.inputErrorMsg}>{error}</div>}
      </React.Fragment>
    </React.Fragment>
  );
}

const BAR_BORDER_RADIUS = '10px';

const getBarStyle = (isFirst, isCompleted, isLast) => {
  const style = {};

  if (isFirst) {
    style.borderTopLeftRadius = BAR_BORDER_RADIUS;
    style.borderBottomLeftRadius = BAR_BORDER_RADIUS;
  }

  if (isLast) {
    style.borderTopRightRadius = BAR_BORDER_RADIUS;
    style.borderBottomRightRadius = BAR_BORDER_RADIUS;
  }

  return style;
};

export function TextInput({
  className,
  defaultValue,
  onKeyUp,
  value,
  refvalue,
  type = "text",
  placeholder,
  onChange,
  isInvalid,
  style,
  name,
  title,
  min,
  max,
  tabIndex,
  onKeyDown,
  onFocus,
  maxLength
}) {
  const modifyEventWithTrimmedValue = useCallback((e) => {
    const newValue = (e.target && e.target.value || '').trim();

    if (!e.currentTarget) {
      e.currentTarget = {};
    }

    if (!e.target) {
      e.target = {};
    }

    e.currentTarget.value = newValue;
    e.target.value = newValue;
  }, []);

  const onBlur = useCallback((e) => {
    modifyEventWithTrimmedValue(e);

    if (onChange) {
      onChange(e);
    }
  }, [onChange, modifyEventWithTrimmedValue]);

  return (
    <div className={className}>
      <div className={styles.textInputLabel}>{title}</div>
      <input
        onKeyUp={onKeyUp}
        ref={refvalue}
        placeholder={placeholder}
        className={`${styles.textInput} ${
          isInvalid ? styles.textInputInvalid : ""
        }`}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        style={style}
        defaultValue={defaultValue}
        name={name}
        tabIndex="-1"
        min={min}
        max={max}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        maxLength={maxLength}
      />
    </div>
  );
}

export function PhoneInput ({
  value,
  title,
  placeholder = undefined,
  onChange,
  isInvalid,
  refvalue,
  onKeyDown,
  onFocus,
}) {
  const classNames = classes(
    styles.textInput,
    isInvalid && styles.textInputInvalid,
  );

  return (
    <div>
      <div className={styles.textInputLabel}>{title}</div>
      <PhoneInputComponent
        containerClass={styles.phoneInputContainer}
        preferredCountries={['us']}
        buttonClass={styles.phoneInputButton}
        dropdownStyle={{ textAlign: 'left' }}
        inputClass={classNames}
        value={value}
        placeholder={''}
        onChange={onChange}
        inputProps={{ ref: refvalue }}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    </div>
  );
}

export function Disclosures({
  onChange,
  onClickPreviousBtn,
  onClickNextBtn,
  isControlPerson,
  isAffiliatedWithSEC_FINRA,
  isYouOrFamilyMemberExposed,
  refvalue,
  onPressEnter,
  isNavigatedFromConfirmation,
  isNextPreviousButtonDisabled,
  onClickSaveAndGoBack,
  currentItemName,
}) {
  const [invalidIsAffiliated, setInvalidIsAffiliated] = useState(false);
  const [invalidIsControlPerson, setInvalidIsControlPerson] = useState(false);
  const [invalidIsExposed, setInvalidIsExposed] = useState(false);

  const [currentFocusedElementIndex, setCurrentFocusedElementIndex] =
    useState(0);

  const refs = [
    ,
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ];

  useEffect(() => {
    setInvalidIsAffiliated(false);
  }, [isAffiliatedWithSEC_FINRA]);

  useEffect(() => {
    setInvalidIsControlPerson(false);
  }, [isControlPerson]);

  useEffect(() => {
    setInvalidIsExposed(false);
  }, [isYouOrFamilyMemberExposed]);

  const handleChange = (type, event) => {
    onChange(type, event.target.value);
  };

  const handleClickNextBtn = () => {
    if (
      isAffiliatedWithSEC_FINRA &&
      isControlPerson &&
      isYouOrFamilyMemberExposed
    ) {
      if (isNavigatedFromConfirmation) {
        onClickSaveAndGoBack();
      } else {
        onClickNextBtn();
      }
    } else {
      setInvalidIsAffiliated(!isAffiliatedWithSEC_FINRA);
      setInvalidIsControlPerson(!isControlPerson);
      setInvalidIsExposed(!isYouOrFamilyMemberExposed);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 38) {
      e.preventDefault();
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      if (
        !isNavigatedFromConfirmation &&
        !isNextPreviousButtonDisabled &&
        currentItemName === slideNames.Disclosures
      ) {
        onClickPreviousBtn();
      }
    }
    if (e.keyCode === 9) {
      e.preventDefault();
      if (currentFocusedElementIndex === 5) {
        setCurrentFocusedElementIndex(0);
        refs[0].focus();
      } else {
        refs[currentFocusedElementIndex + 1].current.focus();
      }
    }
  };

  return (
    <SlideContainer
      title="Please answer these questions"
      onClickNextBtn={handleClickNextBtn}
      onClickPreviousBtn={onClickPreviousBtn}
      id={slideNames.Disclosures}
      isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
      isNavigatedFromConfirmation={isNavigatedFromConfirmation}
    >
      <div>
        <div className={styles.radioGroupTitle} style={{ marginTop: 0 }}>
          Are you or an immediate family member affiliated with or employed by a
          stock exchange, regulatory body, member firm of an exchange, FINRA or
          a municipal securities broker-dealer?
        </div>
        <div
          className="d-flex"
          onChange={(e) => handleChange("isAffiliatedWithSEC_FINRA", e)}
          onKeyUp={(e) => onPressEnter(e, handleClickNextBtn)}
        >
          <div className={styles.radioOption}>
            <input
              tabIndex="-1"
              ref={(ref) => {
                refvalue(ref);
                refs[0] = ref;
              }}
              type="radio"
              id="isAffiliatedWithSEC_FINRAYes"
              name="isAffiliatedWithSEC_FINRA"
              value={true}
              checked={isAffiliatedWithSEC_FINRA === "true"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(0)}
              onKeyDown={onKeyDown}
            />
            <label htmlFor="isAffiliatedWithSEC_FINRAYes">Yes</label>
          </div>
          <div className={styles.radioOption}>
            <input
              ref={refs[1]}
              tabIndex="-1"
              type="radio"
              id="isAffiliatedWithSEC_FINRANo"
              name="isAffiliatedWithSEC_FINRA"
              value={false}
              checked={isAffiliatedWithSEC_FINRA === "false"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(1)}
              onKeyDown={onKeyDown}
            />
            <label htmlFor="isAffiliatedWithSEC_FINRANo">No</label>
          </div>
        </div>
        {invalidIsAffiliated && (
          <div className={styles.inputErrorMsg}>Required</div>
        )}
      </div>
      <div>
        <div className={styles.radioGroupTitle}>
          Are you or an immediate family member an officer or 10% or greater
          shareholder of a publicly traded company, subject to the US Securities
          Exchange Act 1934?
        </div>
        <div
          className="d-flex"
          onChange={(e) => handleChange("isControlPerson", e)}
          onKeyUp={(e) => onPressEnter(e, handleClickNextBtn)}
        >
          <div className={styles.radioOption}>
            <input
              ref={refs[2]}
              tabIndex="-1"
              type="radio"
              id="isControlPersonYes"
              name="isControlPerson"
              value={true}
              checked={isControlPerson === "true"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(2)}
              onKeyDown={onKeyDown}
            />
            <label htmlFor="isControlPersonYes">Yes</label>
          </div>
          <div className={styles.radioOption}>
            <input
              ref={refs[3]}
              tabIndex="-1"
              type="radio"
              id="isControlPersonNo"
              name="isControlPerson"
              value={false}
              checked={isControlPerson === "false"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(3)}
              onKeyDown={onKeyDown}
            />
            <label htmlFor="isControlPersonNo">No</label>
          </div>
        </div>
        {invalidIsControlPerson && (
          <div className={styles.inputErrorMsg}>Required</div>
        )}
      </div>

      <div>
        <div className={styles.radioGroupTitle}>
          Are you or an immediate family member currently or formerly a
          Politically Exposed Person or Public Official?
        </div>
        <div
          className="d-flex"
          onChange={(e) => handleChange("isYouOrFamilyMemberExposed", e)}
          onKeyUp={(e) => onPressEnter(e, handleClickNextBtn)}
        >
          <div className={styles.radioOption}>
            <input
              ref={refs[4]}
              tabIndex="-1"
              type="radio"
              id="isYouOrFamilyMemberExposedYes"
              name="isYouOrFamilyMemberExposed"
              value={true}
              checked={isYouOrFamilyMemberExposed === "true"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(4)}
              onKeyDown={onKeyDown}
            />
            <label htmlFor="isYouOrFamilyMemberExposedYes">Yes</label>
          </div>
          <div className={styles.radioOption}>
            <input
              ref={refs[5]}
              tabIndex="-1"
              type="radio"
              id="isYouOrFamilyMemberExposedNo"
              name="isYouOrFamilyMemberExposed"
              value={false}
              checked={isYouOrFamilyMemberExposed === "false"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(5)}
              onKeyDown={onKeyDown}
            />
            <label htmlFor="isYouOrFamilyMemberExposedNo">No</label>
          </div>
        </div>
        {invalidIsExposed && (
          <div className={styles.inputErrorMsg}>Required</div>
        )}
      </div>
    </SlideContainer>
  );
}

export function PoliticallyExposedPersonIdentity({
  onChange,
  onClickPreviousBtn,
  onClickNextBtn,
  isPoliticallyExposed,
  refvalue,
  isNavigatedFromConfirmation,
  onPressEnter,
  isNextPreviousButtonDisabled,
  currentItemName,
}) {
  const [currentFocusedElementIndex, setCurrentFocusedElementIndex] =
    useState(0);

  const refs = [, createRef()];

  const handleChange = (type, event) => {
    onChange(type, event.target.value);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 40) {
      e.preventDefault();
      if (
        !isNavigatedFromConfirmation &&
        !isNextPreviousButtonDisabled &&
        currentItemName === slideNames.PoliticallyExposedPersonIdentity
      ) {
        onClickPreviousBtn();
      }
    }
    if (e.keyCode === 9) {
      e.preventDefault();
      if (currentFocusedElementIndex === 1) {
        setCurrentFocusedElementIndex(0);
        refs[0].focus();
      } else {
        refs[currentFocusedElementIndex + 1].current.focus();
      }
    }
  };

  return (
    <SlideContainer
      title="Please specify the identity of Politically Exposed Person"
      onClickNextBtn={onClickNextBtn}
      onClickPreviousBtn={onClickPreviousBtn}
      id={slideNames.PoliticallyExposedPersonIdentity}
      isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
      isNavigatedFromConfirmation={isNavigatedFromConfirmation}
    >
      <div>
        <div className={styles.radioGroupTitle} style={{ marginTop: 0 }}>
          This only applies if you or your immediate family member holds a
          prominent public position, for example as a senior government
          official, politician, judicial or military official or political party
          official.
        </div>
        <div
          className="d-flex flex-column"
          onChange={(e) => handleChange("isPoliticallyExposed", e)}
          onKeyUp={(e) => onPressEnter(e, onClickNextBtn)}
        >
          <div className={styles.radioOption} style={{ marginRight: 0 }}>
            <input
              type="radio"
              id="isPoliticallyExposedYes"
              name="isPoliticallyExposed"
              value={true}
              checked={isPoliticallyExposed === "true"}
              onChange={() => {}}
              ref={(ref) => {
                refvalue(ref);
                refs[0] = ref;
              }}
              onFocus={() => setCurrentFocusedElementIndex(0)}
              onKeyDown={onKeyDown}
            />
            <label
              htmlFor="isPoliticallyExposedYes"
              className={styles.radioOptionLabelContianer}
            >
              <div className={styles.radioLabel}>
                I am a senior political figure or government official
              </div>
            </label>
          </div>
          <div className={styles.radioOption} style={{ marginRight: 0 }}>
            <input
              ref={refs[1]}
              type="radio"
              id="isPoliticallyExposedNo"
              name="isPoliticallyExposed"
              value={false}
              checked={isPoliticallyExposed === "false"}
              onChange={() => {}}
              onFocus={() => setCurrentFocusedElementIndex(1)}
              onKeyDown={onKeyDown}
            />
            <label
              htmlFor="isPoliticallyExposedNo"
              className={styles.radioOptionLabelContianer}
            >
              <div className={styles.radioLabel}>
                I am a family member or relative of a senior political figure or
                government official
              </div>
            </label>
          </div>
        </div>
      </div>
    </SlideContainer>
  );
}

export const getSSNFieldSlide = (isCitizenOfUS, visaType) => {
  if (isCitizenOfUS === 'yes') {
    return 4;
  } else if (isCitizenOfUS === 'greenCard') {
    return 5;
  } else if (
    isCitizenOfUS === 'onVisa' && (visaType === 'B1' || visaType === 'B2')
  ) {
    return 7;
  } else if (
    isCitizenOfUS === 'onVisa' && (visaType != 'B1' || visaType != 'B2')
  ) {
    return 6;
  }
};

const VENDOR_AGREEMENTS = [
  'customer_agreement',
  'account_agreement',
  'margin_agreement',
  'terms_privacy_agreement',
];

const NON_VENDOR_AGREEMENTS = [
  'customer_agreement',
  'account_agreement',
  'margin_agreement',
];

const getSSNDisplay = (ssn) => ssn?.length
  ? (
    ssn
      .substring(0, ssn.length - 3)
      .replaceAll(/[0-9]/g, '*')
    + ssn.substring(ssn.length - 3)
  )
  : '';

export function Confirmation ({
  state,
  navigateToField,
  handleSubmit,
  handleChangeAgreementCheckboxes,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [colHeight, setColHeight] = useState(null);
  const firstColRef = createRef();

  const organization = useSelector(selectOrganization);
  const isOrganizationLoading = useSelector(selectIsOrganizationLoading);
  const showVendorAgreement = organization ? isVendor(organization) : false;

  const vendorAgreements = showVendorAgreement
    ? VENDOR_AGREEMENTS
    : NON_VENDOR_AGREEMENTS;

  const buttonDisabled = some(
    vendorAgreements,
    el => !state[el],
  );

  const getSSNFieldIndex = () => getSSNFieldSlide(
    state.isCitizenOfUS,
    state.visaType,
  );

  const getFinancialStatusIndex = () => {
    if (state.employmentStatus === "employed") {
      return getSSNFieldIndex() + 4;
    } else {
      return getSSNFieldIndex() + 3;
    }
  };

  useEffect(() => {
    if (firstColRef.current.clientHeight) {
      setColHeight(firstColRef.current.clientHeight);
    }
  }, [firstColRef]);

  return (
    <div id={slideNames.Confirmation} className="container">
      <h4 className={styles.thankyouMsg}>
        Thank you {state.legalFirstName}! Please check all information
      </h4>
      <div className="row">
        <div className={`col-lg-4 ${styles.infoCol}`}>
          <div className={styles.confirmContainer} ref={firstColRef}>
            <h5 className={styles.confirmLabel}>Identity</h5>
            <div
              onClick={() => navigateToField(0, -1, slideNames.LegalName)}
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Legal first name: </span>
                <span className={styles.infoBoxValue}>
                  {state.legalFirstName}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() => navigateToField(0, -1, slideNames.LegalName)}
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Legal middle name: </span>
                <span className={styles.infoBoxValue}>
                  {state.legalMiddleName}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() => navigateToField(0, -1, slideNames.LegalName)}
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Legal last name: </span>
                <span className={styles.infoBoxValue}>
                  {state.legalLastName}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() => navigateToField(1, 0, slideNames.DateOfBirth)}
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Date of birth: </span>
                <span className={styles.infoBoxValue}>{state.dateOfBirth}</span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() => navigateToField(2, 1, slideNames.IsUSTaxResident)}
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>
                  Are you a US tax resident?:{" "}
                </span>
                <span className={styles.infoBoxValue}>
                  {state.isUSTaxResident === "true" ? "Yes" : "No"}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() => navigateToField(3, 2, slideNames.IsUSCitizen)}
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>
                  Are you a citizen of the USA?:{" "}
                </span>
                <span className={styles.infoBoxValue}>
                  {state.isCitizenOfUS
                    ? isUSCitizenOptions.filter(
                        (fs) => fs.value === state.isCitizenOfUS
                      )[0].name
                    : ""}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            {state.isCitizenOfUS === "onVisa" && (
              <>
                <>
                  <div
                    onClick={() =>
                      navigateToField(4, 3, slideNames.VisaDetails)
                    }
                    className={styles.infoBox}
                  >
                    <div style={{ textAlign: "left" }}>
                      <span className={styles.infoboxlabel}>Visa type: </span>
                      <span className={styles.infoBoxValue}>
                        {state.visaType
                          ? visaTypes.filter(
                              (type) => type.value === state.visaType
                            )[0].name
                          : ""}
                      </span>
                    </div>
                    <img src={EditIcon} alt="" />
                  </div>
                  <div
                    onClick={() =>
                      navigateToField(4, 3, slideNames.VisaDetails)
                    }
                    className={styles.infoBox}
                  >
                    <div style={{ textAlign: "left" }}>
                      <span className={styles.infoboxlabel}>
                        Visa expiration date:{" "}
                      </span>
                      <span className={styles.infoBoxValue}>
                        {state.visaExpirationDate}
                      </span>
                    </div>
                    <img src={EditIcon} alt="" />
                  </div>
                </>
              </>
            )}
            {(state.visaType === "B1" || state.visaType === "B2") && (
              <div
                onClick={() =>
                  navigateToField(5, 3, slideNames.DateOfDeparture)
                }
                className={styles.infoBox}
              >
                <div style={{ textAlign: "left" }}>
                  <span className={styles.infoboxlabel}>
                    Date of departure:{" "}
                  </span>
                  <span className={styles.infoBoxValue}>
                    {state.dateOfDepartureFromUSA}
                  </span>
                </div>
                <img src={EditIcon} alt="" />
              </div>
            )}
            {(state.isCitizenOfUS === "greenCard" ||
              state.isCitizenOfUS === "onVisa") && (
              <>
                <div
                  onClick={() =>
                    navigateToField(
                      getSSNFieldIndex() - 1,
                      3,
                      slideNames.CountryOfCitizenshipAndBirth
                    )
                  }
                  className={styles.infoBox}
                >
                  <div style={{ textAlign: "left" }}>
                    <span className={styles.infoboxlabel}>
                      Country of Citizenship:{" "}
                    </span>
                    <span className={styles.infoBoxValue}>
                      {state.countryOfCitizenship
                        ? countryList.filter(
                            (country) =>
                              country.value === state.countryOfCitizenship
                          )[0].name
                        : ""}
                    </span>
                  </div>
                  <img src={EditIcon} alt="" />
                </div>
                <div
                  onClick={() =>
                    navigateToField(
                      getSSNFieldIndex() - 1,
                      3,
                      slideNames.CountryOfCitizenshipAndBirth
                    )
                  }
                  className={styles.infoBox}
                >
                  <div style={{ textAlign: "left" }}>
                    <span className={styles.infoboxlabel}>
                      Country of Birth:{" "}
                    </span>
                    <span className={styles.infoBoxValue}>
                      {state.countryOfBirth
                        ? countryList.filter(
                            (country) => country.value === state.countryOfBirth
                          )[0].name
                        : ""}
                    </span>
                  </div>
                  <img src={EditIcon} alt="" />
                </div>
              </>
            )}
            <div
              onClick={() =>
                navigateToField(getSSNFieldIndex(), 3, slideNames.SSN)
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>SSN: </span>
                <span className={styles.infoBoxValue}>
                  {getSSNDisplay(state.ssn)}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className={styles.confirmContainer}
            style={colHeight ? { height: `${colHeight}px` } : {}}
          >
            <h5 className={styles.confirmLabel}>Contact information</h5>
            <div
              onClick={() =>
                navigateToField(
                  getSSNFieldIndex() + 1,
                  4,
                  slideNames.ContactInfo
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>
                  Permanent street address:{" "}
                </span>
                <span className={styles.infoBoxValue}>
                  {state.streetAddress}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() =>
                navigateToField(
                  getSSNFieldIndex() + 1,
                  4,
                  slideNames.ContactInfo
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Unit / Apt #: </span>
                <span className={styles.infoBoxValue}>
                  {state.unitApartment}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() =>
                navigateToField(
                  getSSNFieldIndex() + 1,
                  4,
                  slideNames.ContactInfo
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>City: </span>
                <span className={styles.infoBoxValue}>{state.city}</span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() =>
                navigateToField(
                  getSSNFieldIndex() + 1,
                  4,
                  slideNames.ContactInfo
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>State: </span>
                <span className={styles.infoBoxValue}>{state.state}</span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() =>
                navigateToField(
                  getSSNFieldIndex() + 1,
                  4,
                  slideNames.ContactInfo
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>ZIP code: </span>
                <span className={styles.infoBoxValue}>{state.postalCode}</span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
          </div>
        </div>
        <div className={`col-lg-4 ${styles.infoCol}`}>
          <div
            className={styles.confirmContainer}
            style={colHeight ? { height: `${colHeight}px` } : {}}
          >
            <h5 className={styles.confirmLabel}>Financial status</h5>
            <div
              onClick={() =>
                navigateToField(
                  getFinancialStatusIndex(),
                  6,
                  slideNames.FinancialSituation
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>
                  Investable / liquid assets:{" "}
                </span>
                <span className={styles.infoBoxValue}>{state.assetValue}</span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            <div
              onClick={() =>
                navigateToField(
                  getFinancialStatusIndex(),
                  6,
                  slideNames.FinancialSituation
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Funding source: </span>
                <span className={styles.infoBoxValue}>
                  {state.fundingValue
                    ? fundingSources.filter(
                        (fs) => fs.value === state.fundingValue
                      )[0].name
                    : ""}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>

            <div
              onClick={() =>
                navigateToField(
                  getSSNFieldIndex() + 2,
                  5,
                  slideNames.EmploymentStatus
                )
              }
              className={styles.infoBox}
            >
              <div style={{ textAlign: "left" }}>
                <span className={styles.infoboxlabel}>Employment status: </span>
                <span className={styles.infoBoxValue}>
                  {state.employmentStatus
                    ? employmentOptions.filter(
                        (fs) => fs.value === state.employmentStatus
                      )[0].name
                    : ""}
                </span>
              </div>
              <img src={EditIcon} alt="" />
            </div>
            {state.employmentStatus === "employed" && (
              <>
                <div
                  onClick={() =>
                    navigateToField(
                      getSSNFieldIndex() + 3,
                      6,
                      slideNames.EmployerInfo
                    )
                  }
                  className={styles.infoBox}
                >
                  <div style={{ textAlign: "left" }}>
                    <span className={styles.infoboxlabel}>Employer name: </span>
                    <span className={styles.infoBoxValue}>
                      {state.employerName}
                    </span>
                  </div>
                  <img src={EditIcon} alt="" />
                </div>
                <div
                  onClick={() =>
                    navigateToField(
                      getSSNFieldIndex() + 3,
                      6,
                      slideNames.EmployerInfo
                    )
                  }
                  className={styles.infoBox}
                >
                  <div style={{ textAlign: "left" }}>
                    <span className={styles.infoboxlabel}>
                      Employer address:{" "}
                    </span>
                    <span className={styles.infoBoxValue}>
                      {state.employerAddress}
                    </span>
                  </div>
                  <img src={EditIcon} alt="" />
                </div>
                <div
                  onClick={() =>
                    navigateToField(
                      getSSNFieldIndex() + 3,
                      6,
                      slideNames.EmployerInfo
                    )
                  }
                  className={styles.infoBox}
                >
                  <div style={{ textAlign: "left" }}>
                    <span className={styles.infoboxlabel}>
                      Employment position:{" "}
                    </span>
                    <span className={styles.infoBoxValue}>
                      {state.employerPosition}
                    </span>
                  </div>
                  <img src={EditIcon} alt="" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.dCol}`}>
          <div
            className={`${styles.confirmContainer} ${styles.confirmDisclosureSectionContainer}`}
          >
            <h5 className={styles.confirmLabel}>Disclosure</h5>
            <div className={styles.confirmDisclosureSection}>
              <table>
                <tbody>
                  <tr>
                    <td className={styles.disclosureLabel}>
                      Are you or an immediate family member affiliated with or
                      employed by a stock exchange, regulatory body, member firm
                      of an exchange, FINRA or a municipal securities
                      broker-dealer?
                      <div
                        className={styles.disclosureValueInside}
                        onClick={() =>
                          navigateToField(
                            getFinancialStatusIndex() + 1,
                            7,
                            slideNames.Disclosures
                          )
                        }
                      >
                        <div className="d-flex">
                          {state.isAffiliatedWithSEC_FINRA === "true"
                            ? "Yes"
                            : "No"}
                          <img src={EditIcon} alt="" />
                        </div>
                      </div>
                    </td>
                    <td
                      className={styles.disclosureValue}
                      onClick={() =>
                        navigateToField(
                          getFinancialStatusIndex() + 1,
                          7,
                          slideNames.Disclosures
                        )
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {state.isAffiliatedWithSEC_FINRA === "true"
                          ? "Yes"
                          : "No"}
                        <img src={EditIcon} alt="" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.disclosureLabel}>
                      Are you or an immediate family member an officer or 10% or
                      greater shareholder of a publicly traded company, subject
                      to the US Securities Exchange Act 1934?
                      <div
                        className={styles.disclosureValueInside}
                        onClick={() =>
                          navigateToField(
                            getFinancialStatusIndex() + 1,
                            7,
                            slideNames.Disclosures
                          )
                        }
                      >
                        <div className="d-flex">
                          {state.isControlPerson === "true" ? "Yes" : "No"}
                          <img src={EditIcon} alt="" />
                        </div>
                      </div>
                    </td>
                    <td
                      className={styles.disclosureValue}
                      onClick={() =>
                        navigateToField(
                          getFinancialStatusIndex() + 1,
                          7,
                          slideNames.Disclosures
                        )
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {state.isControlPerson === "true" ? "Yes" : "No"}
                        <img src={EditIcon} alt="" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.disclosureLabel}>
                      Are you or an immediate family member currently or
                      formerly a Politically Exposed Person or Public Official?
                      <div
                        className={styles.disclosureValueInside}
                        onClick={() =>
                          navigateToField(
                            getFinancialStatusIndex() + 1,
                            7,
                            slideNames.Disclosures
                          )
                        }
                      >
                        <div className="d-flex">
                          {state.isYouOrFamilyMemberExposed === "true"
                            ? "Yes"
                            : "No"}
                          <img src={EditIcon} alt="" />
                        </div>
                      </div>
                    </td>
                    <td
                      className={styles.disclosureValue}
                      onClick={() =>
                        navigateToField(
                          getFinancialStatusIndex() + 1,
                          7,
                          slideNames.Disclosures
                        )
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {state.isYouOrFamilyMemberExposed === "true"
                          ? "Yes"
                          : "No"}
                        <img src={EditIcon} alt="" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <h5 className={styles.agreementMsg}>
        Please read agreements carefully before continuing:
      </h5>
      <div>
        <div
          className={`${styles.accordianLabel} ${
            isDropdownOpen ? styles.accordianLabelActive : ""
          }`}
        >
          {isOrganizationLoading
            ? (
              <Loader centralize />
            )
            : (
              <>
                <span>
                  <Checkbox
                    value={every(vendorAgreements.map(name => state[name]))}
                    onChange={(e) => handleChangeAgreementCheckboxes("all", e)}
                    customStyle={{ marginBottom: 0 }}
                    title={showVendorAgreement ? <VendorAgreement /> : <NonVendorAgreement />}
                  />
                </span>
                <div
                  className={styles.aggrementDropdownIconBtn}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <img src={DownArrowDarkGrayIcon} alt="icon" />
                </div>
              </>
            )
          }
        </div>
        <div
          className={`${styles.accordianContent} ${
            isDropdownOpen ? styles.accordianContentActive : ""
          }`}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Checkbox
                onChange={(e) =>
                  handleChangeAgreementCheckboxes("customer_agreement", e)
                }
                title="I have read, understood, and agree to be bound by Alpaca Securities LLC
                and Eko Investments Inc. account terms, and all other terms, disclosures
                and disclaimers applicable to me, as referenced in the Alpaca Customer
                Agreement. I also acknowledge that the Alpaca Customer Agreement contains
                a pre-dispute arbitration clause in Section 43."
                value={state.customer_agreement}
              />
              <Checkbox
                onChange={(e) =>
                  handleChangeAgreementCheckboxes("account_agreement", e)
                }
                title="I understand I am signing this agreement electronically and that
                my electronic signature is the legal equivalent of my manual
                signature on this Agreement."
                value={state.account_agreement}
              />
              <Checkbox
                onChange={(e) =>
                  handleChangeAgreementCheckboxes("margin_agreement", e)
                }
                title="I have read and agree with the Investment Management Agreement"
                value={state.margin_agreement}
              />
              {showVendorAgreement && (
                <Checkbox
                  onChange={(e) =>
                    handleChangeAgreementCheckboxes("terms_privacy_agreement", e)
                  }
                  title="I have read and agree with Terms of Use and Privacy Policy"
                  value={state.terms_privacy_agreement}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {state.error && <div className={styles.error}>{state.error}</div>}
      <button
        tabIndex="-1"
        onClick={handleSubmit}
        disabled={buttonDisabled}
        className={buttonDisabled ? styles.finishBtnDisabled : styles.finishBtn}
      >
        {state.isLoading || isOrganizationLoading ? <Loader /> : "Finish"}
      </button>
    </div>
  );
}
