import React from 'react';
import { FormItemProps } from 'antd';

import { CompositeInputProps, Input, InputProps, InputType } from '~/ui/kit/molecules/Input';

import * as Styled from './Form.organism.styled';

const getInput = (input: InputProps) => {
  const commonProps = {
    placeholder: input.placeHolder,
    onKeyPress: input.onKeyPress,
    onChange: input.onChange,
    $bordered: input.$bordered,
    $br: input.$br,
    $fs: input.$fs,
    $fw: input.$fw,
    $ff: input.$ff,
  };
  switch (input.type) {
    case InputType.STRING: {
      return <Input.String
        {...commonProps}
        name={input.id}
        placeholder={input.placeHolder}
        onChange={input.onChange}
        pattern={input.pattern}
        $bordered={input.$bordered}
        type={input.htmlType}
      />;
    }
    case InputType.PHONE: {
      return <Input.Phone
        {...commonProps}
        value={input.value}
        onChange={input.onChange}
      />;
    }
    case InputType.NUMBER: {
      return <Input.Number
        {...commonProps}
        min={input.min}
        max={input.max}
        step={input.step}
        controls={input.controls}
        keyboard={input.keyboard}
        parser={input.parser}
        formatter={input.formatter}
      />;
    }
    case InputType.DATE: {
      return <Styled.DateInput
        {...commonProps}
        picker={input.picker}
        disabledDate={input.disabledDate}
        showToday={input.showToday}
        defaultPickerValue={input.defaultPickerValue}
      />;
    }
    case InputType.RADIO: {
      return <Input.RadioGroup {...commonProps} $layout={input.layout} options={input.options}/>;
    }
    case InputType.SELECT: {
      return <Input.Select
        {...commonProps}
        before={input.before}
        options={input.options}
        placeholder={input.placeHolder}
        showSearch={input.showSearch}
        filterOption={input.filterOption}
      />;
    }
    case InputType.CUSTOM: {
      if ('key' in input.Component) {
        return input.Component;
      }
      else {
        return <input.Component />;
      }
    }
  }
};

export const mapFormItem = (formItem: InputProps | CompositeInputProps) => {
  if (formItem.type === InputType.COMPOSITE) {
    return (
      <Styled.CompositeItemContainer>
        {formItem.label && (
          <Styled.CompositeLabelContainer>
            <span>{formItem.label}</span>
            {formItem.description}
          </Styled.CompositeLabelContainer>
        )}
        {Array.isArray(formItem.inputs) ? formItem.inputs.map(mapFormItem) : formItem.inputs}
      </Styled.CompositeItemContainer>
    );
  }

  const commonProps: FormItemProps = {
    name: formItem.id,
    label: (formItem.label || formItem.labelDescription) && (
      <Styled.LabelContainer>
        {formItem.label && <Styled.Label>{formItem.label}</Styled.Label>}
        {formItem.labelDescription && <Styled.LabelDescription>{formItem.labelDescription}</Styled.LabelDescription>}
      </Styled.LabelContainer>
    ),
    rules: [{
      required: formItem.required,
      message: formItem.requireMessage || `Required`,
    }, ...(formItem.validationRules || [])]
  };

  let controls;

  if (formItem.Controls) {
    if (typeof formItem.Controls === 'function') {
      controls = <Styled.Description><formItem.Controls /></Styled.Description>;
    }
    else {
      controls = <Styled.Description>{formItem.Controls}</Styled.Description>;
    }
  }

  return (
    <Styled.ItemContainer key={formItem.id}>
      <Styled.Item {...commonProps}>{getInput(formItem)}</Styled.Item>
      {formItem.description && <Styled.Description>{formItem.description}</Styled.Description> }
      {controls}
    </Styled.ItemContainer>
  );
};
