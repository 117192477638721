import styled, { css } from 'styled-components';
import { Radio as ARadio } from 'antd';
import { media } from '~/ui/utils/media';

const verticalLayout = css`
  display: flex;
  flex-direction: column;
  text-align: left;

  .ant-radio-wrapper {
    margin-top: 20px;
  }
`;

export const RadioGroup = styled(ARadio.Group)<{$layout?: 'vertical' | 'horizontal'}>`
  ${({ $layout }) => $layout === 'vertical' && verticalLayout};
  display: flex;

  .ant-radio-wrapper {
    font-size: 14px !important;
    margin-right: 30px !important;
    
    ${media.sm`
      font-size: 16px !important;
    `}
    
    .ant-radio {
      &-inner {
        border-color: grey;
        border-width: 1.5px;
        transition: none;
      }
    }

    &-checked {
      color: var(--eko-primary-color);

      .ant-radio {
        color: currentColor;
      }

      .ant-radio-inner {
        background-color: unset;
        border-color: unset;

        &:after {
          transform: scale(0.575);
          background-color: currentColor;
          border-color: currentColor;
        }
      }
    }
  }
`;
