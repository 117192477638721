import React from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker } from 'antd';

import * as CommonStyles from './Input.styled';

const Styles = createGlobalStyle`
  .ant-picker {
    &-cell {
      &-selected &-inner {
        background: var(--eko-primary-color) !important;
      }
    }

    &-month-btn, &-year-btn {
      ${CommonStyles.fontColor};
    }
  }
`;

const StyledDate = styled(DatePicker)`
  width: 100%;
  ${CommonStyles.underlinedBorder};
  ${CommonStyles.errorColors};

  &.ant-picker {
    box-shadow: none !important;
  }

  .ant-picker-suffix {
    color: currentColor;
  }
`;

export const Date = (props) => (
  <>
    <Styles/>
    <StyledDate {...props} />
  </>
);
