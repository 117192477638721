import React, { FC, useCallback } from 'react';
import { MenuProps } from 'antd';

import * as S from './Menu.styled';

export type MenuItem = Required<MenuProps>['items'][number];

type Props = {
  className?: string;
  items: MenuItem[];
  activeMenuItem: string;
  onItemSelect: (menuItemKey: string) => void
};

export const Menu: FC<Props> = (props) => {
  const onClick: MenuProps['onClick'] = useCallback(({ key }) => {
    props.onItemSelect(key);
  }, [props.onItemSelect]);

  return (
    <S.Container
      className={props.className}
      items={props.items}
      defaultActiveFirst
      selectedKeys={[props.activeMenuItem]}
      onClick={onClick}
    />
  );
};
