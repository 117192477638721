import React, { createRef, ForwardedRef, useCallback, useEffect, useRef } from 'react';
import { SelectProps as ASelectProps } from 'antd';

import { useOutsideEvent } from '~/ui/utils/effects/useOutsideEvent.hook';

import * as Styled from './Select.molecule.styled';

type SelectProps = ASelectProps & {
  fullWidth?: boolean;
  before?: React.ReactNode;
  isTextLabelDropdown?: boolean;
  valueControl?: React.ReactNode;
}

const defaultIcon = <Styled.Icon/>;

export const Select = React.forwardRef<ForwardedRef<any>, SelectProps>(({
  suffixIcon,
  dropdownRender,
  fullWidth,
  before,
  isTextLabelDropdown,
  valueControl,
  className,
  virtual = false,
  ...props
}, ref) => {
  const dropdownRenderRef = createRef<HTMLDivElement>();

  const renderDropdown: typeof dropdownRender = useCallback((children) => {
    return (
      <>
        <Styled.Dropdown ref={dropdownRenderRef} $fullWidth={fullWidth}>
          {before}
          {children}
        </Styled.Dropdown>
      </>
    );
  }, [fullWidth, before, dropdownRenderRef]);

  const selectRef = useRef(null);

  const setRef = useCallback((r) => {
    selectRef.current = r;

    if (typeof ref === 'function') {
      ref(r);
    }
    else {
      if (ref) {
        ref.current = r;
      }
    }
  }, [ref]);

  const isVisibleRef = useRef<boolean>(props.defaultOpen || false);

  const onVisibilityChange = useCallback((val: boolean) => {
    setTimeout(() => {
      isVisibleRef.current = val;
    }, 200);
  }, []);

  const hideDropdown = useCallback(() => {
    if (isVisibleRef.current) {
      selectRef.current?.blur && selectRef.current.blur();
    }
  }, []);

  useOutsideEvent(dropdownRenderRef, 'touchStart', hideDropdown);

  useEffect(() => {
    document.addEventListener('outsideScroll', hideDropdown);

    return () => {
      document.removeEventListener('outsideScroll', hideDropdown);
    };
  }, [hideDropdown]);

  return (
    <Styled.Container className={className}>
      {valueControl}
      <Styled.Select
        virtual={virtual}
        onDropdownVisibleChange={onVisibilityChange}
        {...props}
        $hideValue={!!valueControl}
        $isTextLabelDropdown={isTextLabelDropdown}
        ref={setRef}
        $fullWidth={fullWidth}
        suffixIcon={suffixIcon === undefined ? defaultIcon : suffixIcon}
        dropdownRender={dropdownRender || renderDropdown}
      />
    </Styled.Container>
  );
});
