import React, { FC } from 'react';
import loadable from '@loadable/component';

import { AccountSkeleton } from './ui/Skeleton';

import * as S from './AccountPage.common.styled';

type Props = {
  className?: string;
  hasAlpacaAccount?: boolean;
};

const AccountPageContent = loadable(() => import('./Account.page'), { fallback: AccountSkeleton });

export const AccountPage: FC<Props> = ({ className, ...props }) => {
  return (
    <S.Container title="Account" className={className}>
      <AccountPageContent {...props}/>
    </S.Container>
  );
};
