import styled, { createGlobalStyle, css } from 'styled-components';
import { Modal } from 'reactstrap';
import { Button } from '~/ui/kit/atoms/Button';
import { baseFont, fontSize } from '~/ui/kit/constants/fonts';
import { gaps, getBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

export const GlobalStyles = createGlobalStyle`
  body {
    display: flex;
    flex-direction: column-reverse;
  }

  .eko-modal-wrapper {
    touch-action: none;
  }

  .modal-open {
    & .modal, & .modal-backdrop {
      height: calc(var(--q2-viewport-height, 100vh) - var(--q2-header-size, 0px) + 5px);
      top: calc(var(--q2-header-size, 0px) - 5px);
      position: absolute;
      touch-action: none;
    }
  }
`;

export const Container = styled(Modal).attrs({ $br: 'xl' })`
  --bs-modal-width: calc(100% - ${gaps.xxl}px);

  ${media.md`
    --bs-modal-width: 700px;
  `}
  .modal-content {
    border: none;
    touch-action: none;
    ${baseFont};
    /** Use Container element as the nearest parent relative element to have a simple way
        to set 100% width for Description:after element border */
    position: relative;
    background: var(--eko-background-color);
    padding: ${gaps.m}px;
    ${getBorderRadius}
  }
`;

export const HeaderContainer = styled.div<{ $titleInHeader: boolean }>`
  display: flex;
  justify-content: ${({ $titleInHeader }) => $titleInHeader ? 'space-between' : 'flex-end'};
  margin-bottom: ${gaps.s}px;
`;

export const CloseButton = styled(Button).attrs({ type: 'ghost' })`
  padding: 0;
  color: var(--eko-third-color-dark);
`;

export const ContentContainer = styled.div`
  background: var(--eko-background-color);
`;

export const BaseTitle = styled.div.attrs({ $fs: 'l' })`
  font-weight: bold;
  ${fontSize};
  text-align: center;
  color: var(--eko-fourth-color);

  & > img {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
`;

export const Title = styled(BaseTitle)`
  margin-bottom: ${gaps.m}px;

  ${media.md`
    margin-bottom: ${gaps.xl}px;
  `}
  
`;

export const Subtitle = styled.div.attrs({ $fs: 's' })`
  ${fontSize};
  text-align: center;
  color: var(--eko-third-color);
  margin: ${gaps.l}px auto ${gaps.xxl}px auto;
  max-width: 480px;
`;

export const ChildrenContent = styled.div`
  margin: auto auto ${gaps.l}px;
  max-width: 480px;

  ${media.md`
    margin-bottom: ${gaps.m}px;
  `}
`;

export const ActionsContainer = styled.div<{ $disableBottomMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ $disableBottomMargin }) => !$disableBottomMargin && css`
    margin-bottom: ${gaps.xl}px;
    ${media.sm`
      margin-bottom: ${gaps.xxl}px;
    `}
  `}
  gap: ${gaps.m}px;

  ${media.sm`
    flex-direction: row;
    gap: ${gaps.xxl}px;
  `}
  &:empty {
    display: none;
  }
`;

export const ActionButton = styled(Button).attrs({ size: 'large', fs: 'm' })`
  font-weight: bold;
  width: 100%;

  ${media.sm`
    width: 60%;
    max-width: 190px;
  `}
  &:only-child {
    width: 60%;
    max-width: 190px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type OrderProps = {
  $order?: 0 | 1;
}
export const Confirm = styled(ActionButton).attrs({ type: 'primary' })<OrderProps>`
  ${({ $order }) => $order && css`
    order: ${$order};
  `}
`;

export const Decline = styled(ActionButton)``;

export const Description = styled.div.attrs({ $fs: 's' })`
  ${fontSize};
  text-align: center;
  // Can not use margin-top 'cuz of margin collapsing
  padding-top: ${gaps.m}px;
  color: var(--eko-third-color);
  max-width: 570px;
  margin: auto;

  &:before {
    content: '';
    height: 1px;
    background: var(--eko-primary-color-light-2);
    left: 0;
    right: 0;
    position: absolute;
    /** As before element positioned with an absolute value based on Container.modal-content we can not set top: 0
        so we move it with translate prop to align it to the top of Description component */
    transform: translateY(-${gaps.m}px);
  }
`;
