import React from 'react';
import { TooltipProps } from 'antd';

import * as Styled from './InfoTooltip.styled';

type InfoTooltipProps = TooltipProps
export const InfoTooltip: React.FC<InfoTooltipProps> = (props) => {
  return (
    <Styled.Container {...props}>
      <Styled.TooltipStyles />
      {props.children || <Styled.Icon />}
    </Styled.Container>
  );
};
