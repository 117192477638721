import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Collapse } from 'antd';

import { gaps, getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';
import { getFontSize } from '~/ui/kit/constants/fonts';
import { IconArrow } from '~/ui/kit/atoms/IconArrow';

const ExpandArrow = styled(IconArrow)`
  width: 17px;
  height: 17px;
  transform: rotateZ(-180deg);
  transition: transform .3s;
`;

export const Container = styled(Collapse).attrs({
  accordion: true,
  ghost: true,
  expandIconPosition: 'end',
  expandIcon: () => React.createElement(ExpandArrow)
})`

  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    border: 1px solid var(--eko-fifth-color);
    border-top: unset;
    border-bottom-right-radius: ${getEkoBorderRadius({ $br: 'l' })};
    border-bottom-left-radius: ${getEkoBorderRadius({ $br: 'l' })};
  }
`;

const itemFont = css`
  ${getFontSize('s')};
`;

export const GlobalStyles = createGlobalStyle`
  .ant-collapse {
    &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
      padding: 0;
    }

    & > &-item {
      margin-bottom: ${gaps.xs}px;

      &:last-child {
        & > .ant-collapse-header {
          border-radius: ${getEkoBorderRadius({ $br: 'l' })};
        }
      }

      &.ant-collapse-item-active {
        & > .ant-collapse-header {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        
        ${ExpandArrow} {
          transform: rotateZ(0);
        }
      }

      & > .ant-collapse-header {
        background-color: var(--eko-fifth-color);
        padding: ${gaps.s}px ${gaps.m}px;
        border-radius: ${getEkoBorderRadius({ $br: 'l' })};
        transition: .3s border-radius;
        ${itemFont};
        font-weight: bold;
        align-items: center;
        gap: ${gaps.xxs}px;

        ${media.md`
          padding: ${gaps.m}px;
        `}
      }
    }

    & &-content {
      border: none;

      > .ant-collapse-content-box {
        background-color: white;
        padding: ${gaps.s}px ${gaps.m}px;
        ${itemFont};
      }
    }
  }
`;
