import React, { MouseEventHandler, PropsWithChildren, useMemo } from 'react';

import * as Styled from './ListItem.organism.styled';

type Props = PropsWithChildren<{
  className?: string;
  after?: React.ReactNode;
  subtitle?: React.ReactNode;
  onClick?: MouseEventHandler
}>

export const ListItem: React.FC<Props> = (props) => {
  const Wrapper = useMemo(() => props.onClick ? Styled.ClickWrapper : ({ children }) => children, [props.onClick]);

  return (
    <Styled.Container className={props.className}>
      <Wrapper onClick={props.onClick}>
        <Styled.Content>
          {props.children}
          {props.subtitle && <Styled.Subtitle>{props.subtitle}</Styled.Subtitle>}
        </Styled.Content>
        {props.after}
      </Wrapper>
    </Styled.Container>
  );
};
