import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const IconTrash: FC<Props> = (props) => (
  <svg className={props.className} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.25 2H9.5V1.5C9.5 0.672906 8.82709 0 8 0H6C5.17291 0 4.5 0.672906 4.5 1.5V2H1.75C1.06075 2 0.5 2.56075 0.5 3.25V5C0.5 5.27612 0.723875 5.5 1 5.5H1.27325L1.70522 14.5713C1.74338 15.3725 2.4015 16 3.2035 16H10.7965C11.5985 16 12.2567 15.3725 12.2948 14.5713L12.7268 5.5H13C13.2761 5.5 13.5 5.27612 13.5 5V3.25C13.5 2.56075 12.9392 2 12.25 2ZM5.5 1.5C5.5 1.22431 5.72431 1 6 1H8C8.27569 1 8.5 1.22431 8.5 1.5V2H5.5V1.5ZM1.5 3.25C1.5 3.11216 1.61216 3 1.75 3H12.25C12.3878 3 12.5 3.11216 12.5 3.25V4.5C12.3459 4.5 2.13853 4.5 1.5 4.5V3.25ZM11.2959 14.5238C11.2832 14.7908 11.0638 15 10.7965 15H3.2035C2.93616 15 2.71678 14.7908 2.70409 14.5238L2.27437 5.5H11.7256L11.2959 14.5238Z"
      fill="currentColor"/>
    <path
      d="M7 14C7.27613 14 7.5 13.7761 7.5 13.5V7C7.5 6.72387 7.27613 6.5 7 6.5C6.72387 6.5 6.5 6.72387 6.5 7V13.5C6.5 13.7761 6.72384 14 7 14Z"
      fill="currentColor"/>
    <path
      d="M9.5 14C9.77613 14 10 13.7761 10 13.5V7C10 6.72387 9.77613 6.5 9.5 6.5C9.22387 6.5 9 6.72387 9 7V13.5C9 13.7761 9.22384 14 9.5 14Z"
      fill="currentColor"/>
    <path
      d="M4.5 14C4.77613 14 5 13.7761 5 13.5V7C5 6.72387 4.77613 6.5 4.5 6.5C4.22387 6.5 4 6.72387 4 7V13.5C4 13.7761 4.22384 14 4.5 14Z"
      fill="currentColor"/>
  </svg>

);
