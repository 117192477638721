import styled from 'styled-components';
import { Skeleton } from 'antd';

export const Block = styled(Skeleton.Button)`
  &.ant-skeleton {
    &-element &-button {
      height: 100%;
    }
  }
`;

Block.defaultProps = {
  active: true,
  block: true,
};
