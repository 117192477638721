import React, { FC } from 'react';

import { formatMoney } from '@utils/money';
import { FontSizeProps } from '~/ui/kit/constants/fonts';

import * as Styled from './AccountInfo.molecule.styled';

type Props = {
  label: string;
  accountNumber: string;
  className?: string;
  accountType?: string;
  balance?: number;
  isConnected?: boolean;
} & FontSizeProps;

export const AccountInfo: FC<Props> = (props) => {
  return (
    <Styled.Container className={props.className} $fs={props.$fs}>
      <Styled.AccountName>
        {props.label}
        <Styled.Number number={props.accountNumber} />
        {props.isConnected && <Styled.ConnectedBadge>Connected</Styled.ConnectedBadge>}
      </Styled.AccountName>

      <Styled.InfoContainer>
        {props.accountType && <Styled.AccountType>{props.accountType}</Styled.AccountType>}
        {props.balance !== undefined && <Styled.Balance>Balance: ${formatMoney(props.balance)}</Styled.Balance>}
      </Styled.InfoContainer>
    </Styled.Container>
  );
};

AccountInfo.defaultProps = {
  $fs: 'xs'
};
