import { css } from 'styled-components';

const BORDER_RADIUS = {
  xs: 4,
  s: 6,
  m: 8,
  l: 10,
  xl: 15,
} as const;

export type BorderRadius = keyof typeof BORDER_RADIUS

export type BorderRadiusProps = { $br?: BorderRadius }

export const getEkoBorderRadius = ({ $br }: BorderRadiusProps) => {
  const fallbackValue = `${BORDER_RADIUS[$br]}px`;

  switch ($br) {
    case 'xs': return `var(--eko-border-radius-x-small, ${fallbackValue})`;
    case 's': return `var(--eko-border-radius-small, ${fallbackValue})`;
    case 'm': return `var(--eko-border-radius-medium, ${fallbackValue})`;
    case 'l': return `var(--eko-border-radius, ${fallbackValue})`;
    case 'xl': return `var(--eko-border-radius-x-large, ${fallbackValue})`;
  }

  return '0';
};

export const getBorderRadius = css<BorderRadiusProps>`
  border-radius: ${getEkoBorderRadius};
`;
