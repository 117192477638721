import styled from 'styled-components';

import { baseFont } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

import { Container as NavMenuContainer, NavMenu } from '~/ui/layouts/NavMenu/NavMenu.styled';
import { PageLayout } from '~/ui/layouts/Page';

export const Container = styled(PageLayout).attrs({
  hideFooterDisclaimer: true
})`
  ${baseFont};
  
  ${NavMenuContainer} {
    @media (max-width: 991px) {
      background: none !important;
    } 
  }
  
  ${NavMenu} {
    &.ant-menu {
      &.ant-menu-root.ant-menu-vertical {
        gap: ${gaps.s}px;
      }
    }
  }
`;
