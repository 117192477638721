import React, { FC } from 'react';

import PhoneInput from 'react-phone-input-2';
import validator from 'validator';

import * as S from './Phone.styled';

type Props = {
  className?: string;
  value?: string;
  onChange?: (val: string) => void;
  $bordered?: boolean;
};

const noBackground = { background: 'none' };
const phoneInputStyles = { border: 'none', ...noBackground };

export const Phone: FC<Props> = ({
  className,
  value,
  onChange,
  ...props }) => {
  return (
    <>
      <S.Styles {...props} />
      <PhoneInput
        inputStyle={props.$bordered ? noBackground : phoneInputStyles}
        containerStyle={phoneInputStyles}
        containerClass={className}
        placeholder="Phone number"
        value={value}
        onChange={onChange}
        buttonStyle={phoneInputStyles}
        country="us"
      />
    </>
  );
};

export const defaultPhoneValidator = {
  validator: (_, value) => {
    if (!validator.isMobilePhone(value)) {
      return Promise.reject(new Error('Invalid phone number'));
    }
    return Promise.resolve();
  }
};
