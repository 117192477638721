import { InputRef } from 'antd';
import React, { useEffect, useState } from 'react';

import * as money from '@utils/money';

import * as Styled from './Amount.molecule.styled';

const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

type AmountProps = { value: number, onChange: (amount: number) => void, prefix?: string }
export const Amount = React.forwardRef<InputRef, AmountProps>((props, ref) => {
  const prefix = props.prefix || '';
  const [localValue, setLocalValue] = useState('0');

  useEffect(() => {
    if (props.value && !Number.isNaN(Number(props.value))) {
      setLocalValue(props.value.toString());
    }
  }, [props.value]);

  useEffect(() => {
    const numberAmount = money.parse(localValue.replace(prefix, ''));
    if (localValue === prefix || localValue === '') {
      props.onChange(0);
    }
    else {
      if (!Number.isNaN(numberAmount)) {
        props.onChange(numberAmount);
      }
    }
  }, [localValue]);

  return (
    <Styled.AmountInput
      ref={ref}
      type="text"
      inputMode="decimal"
      value={`${prefix}${localValue}`}
      onBlur={() => {
        setLocalValue(money.format(localValue, '0,0[.]00'));
      }}
      onFocus={() => {
        setLocalValue(money.parse(localValue).toString());
      }}
      onChange={(e) => {
        const amount = e.target.value.replace(prefix, '').replace(',', '.');
        if (amount === '') {
          setLocalValue('0');
        }
        if (amount === '.') {
          setLocalValue('0.');
        }
        if (floatRegExp.test(amount)) {
          const [_, decimalPart] = amount.split('.');
          if (decimalPart && decimalPart.length > 2) {
            return;
          }
          setLocalValue(amount);
        }
      }
      }
    />
  );
});
