import React, { FC } from 'react';

import * as S from './FAQ.styled';

type Props = {
  className?: string;
};

const Skeleton: FC<Props> = (props) => {
  return (
    <S.Container className={props.className} />
  );
};

export const FAQSkeleton = <Skeleton />;
