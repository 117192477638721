import styled, { css } from 'styled-components';
import { Select as ASelect } from 'antd';

import { media } from '~/ui/utils/media';

import { baseFont } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';
import { IconArrow } from '~/ui/kit/atoms/IconArrow';

import * as CommonStyles from '../Input.styled';

export const Icon = styled(IconArrow)`
  width: 12px;
`;

const fullWidthCommonStyles = css<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) => $fullWidth && css`
    padding: ${gaps.s - CommonStyles.INPUT_BORDER_WIDTH}px;

    ${media.md`
        padding: ${gaps.s - CommonStyles.INPUT_BORDER_WIDTH}px ${gaps.m - CommonStyles.INPUT_BORDER_WIDTH}px;
    `}

    ${media.xl`
        padding: ${gaps.m - CommonStyles.INPUT_BORDER_WIDTH}px ${gaps.xl - CommonStyles.INPUT_BORDER_WIDTH}px;
    `}
  `}

`;

const textLabelStyles = css<{disabled?: boolean}>`
  color: var(--eko-primary-color);

  ${({ disabled }) => disabled && css`
    color: rgba(0, 0, 0, 0.25);
  `};
  &.ant-select {
    &.ant-select-in-form-item {
      width: auto;
      border: none;
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: unset;
    }
  }
  
  &.ant-select-single {
    font-size: inherit;
  }

  & .ant-select-selector {
    display: flex;
    align-items: center;
    border: none !important;
    height: auto !important;
    color: inherit;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    font-size: inherit;

    & .ant-select-selection-item {
      font-size: inherit;
      font-weight: inherit;
      
      &, &:after {
        line-height: unset;
      }
    }
  }
`;

export const Container = styled.div`
    position: relative;
`;

export const Select = styled(ASelect)<{
  $fullWidth?: boolean;
  $isTextLabelDropdown?: boolean,
  $hideValue?: boolean,
}>`
  ${({ $fullWidth }) => $fullWidth && 'width: 100%'};

  &.ant-select {
    &-single {
      .ant-select-selector .ant-select-selection-item:after {
        content: none;
      }
      
      ${({ $hideValue }) => $hideValue && css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: hidden;

        .ant-select-selector {
          height: 100% !important;
          width: 100%;
        }
      `};
    }

    .ant-select-arrow {
      transform: rotateX(180deg);
    }

    &-open {
      .ant-select-arrow {
        transform: rotateX(0);
      }
    }

    .ant-select {
      &-arrow {
        color: currentColor;
        transition: all 0.5s;
      }

      &-selection-item {
        color: inherit;
      }

      &-selector {
        text-align: left;
        ${CommonStyles.border};
        ${CommonStyles.borderColor};
        ${CommonStyles.boxShadow};
        padding: ${gaps.xxs - CommonStyles.INPUT_BORDER_WIDTH}px ${gaps.m - CommonStyles.INPUT_BORDER_WIDTH}px;
        height: auto;
        ${baseFont};
        ${media.md`
            padding: ${gaps.s - CommonStyles.INPUT_BORDER_WIDTH}px ${gaps.xl - CommonStyles.INPUT_BORDER_WIDTH}px;
        `};
        ${fullWidthCommonStyles};
      }
    }
  }
  
  ${({ $isTextLabelDropdown }) => $isTextLabelDropdown && textLabelStyles};
`;

export const BeforeContainer = styled.div``;

export const Dropdown = styled.div<{ $fullWidth?: boolean }>`
  margin: -4px;
  border: 2px solid var(--eko-fifth-color);
  border-radius: 10px;
  overflow: hidden;
  min-width: 125px;

  .ant-select-item, ${BeforeContainer} {
    border-radius: 0;
    ${fullWidthCommonStyles};
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--eko-primary-color-light-2);
    color: var(--eko-primary-color);
  }
`;
