import React, { useState, useEffect, useContext } from "react";
import { Fields as VisaFields } from './new2/slides/visa_details/context';
import { ThemeContext } from '~/contexts/app';
import styles from './OnboardingAlpaca.module.css';
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import { SlideContainerKYC, UploadInput } from "./OnboardingAlpacaFields";
import { alpacaAccountInfo, submitDocuments } from "../../../services/alpaca";
import Loader from "../../components/Loader/Loader";
import VisaVerification from '@pages/onboarding_alpaca/kyc/verification_slides/Visa';
import { VisaDetailsDefaults } from '@pages/onboarding_alpaca/new2/slides/visa_details/context';
import brokerApi from '@api/endpoints/broker';
import { response } from '@api/helpers';
import { getRequiredKYCTypes } from '@services/broker-account';

export const types = {
  ACTION_REQUIRED: "ACTION_REQUIRED",
  TAX_IDENTIFICATION: "TAX_IDENTIFICATION",
  IDENTITY_VERIFICATION: "IDENTITY_VERIFICATION",
  INVALID_IDENTITY_PASSPORT: "INVALID_IDENTITY_PASSPORT",
  VISA_TYPE_OTHER: "VISA_TYPE_OTHER",
  ADDRESS_VERIFICATION: "ADDRESS_VERIFICATION",
  DATE_OF_BIRTH: "DATE_OF_BIRTH",
  PEP: "PEP",
  FAMILY_MEMBER_PEP: "FAMILY_MEMBER_PEP",
  AFFILIATED: "AFFILIATED",
  CONTROL_PERSON: "CONTROL_PERSON",
  REJECTED: "REJECTED",
  APPROVAL_PENDING: "APPROVAL_PENDING",
  ACTIVE: "ACTIVE",
  APPROVED: "APPROVED",
  ACCOUNT_CLOSED: "ACCOUNT_CLOSED",
};

const typeScreens = {
  [types.IDENTITY_VERIFICATION]: 'identity',
  [types.INVALID_IDENTITY_PASSPORT]: 'identity',
  [types.DATE_OF_BIRTH]: 'identity',
  [types.TAX_IDENTIFICATION]: 'tax',
  [types.ADDRESS_VERIFICATION]: 'address',
  [types.VISA_TYPE_OTHER]: 'visa',
};

const inputTypeActionItems = [
  types.TAX_IDENTIFICATION,
  types.IDENTITY_VERIFICATION,
  types.ADDRESS_VERIFICATION,
  types.DATE_OF_BIRTH,
  types.INVALID_IDENTITY_PASSPORT,
  types.VISA_TYPE_OTHER,
];

class KYC extends React.Component {
  static contextType = ThemeContext

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentFormItem: 0,
      isNextPreviousButtonDisabled: false,
      inputTypeItemsLength: null,
      submitLoading: false,
      error: null,
      actionItems: [],
      requiredTypes: [],

      tax_id_verification: null,
      address_verification: null,
      identity_verification_front: null,
      identity_verification_back: null,

      response: null,

      ...VisaDetailsDefaults,
    };
  }
  componentDidMount() {
    this.getAlpacaAccountInfoAsync();
    window.addEventListener("resize", () => {
      if (this.state.currentFormItem) {
        this.setSlideDynamicHeight(this.state.currentFormItemName);
      }
    });
  }

  getAlpacaAccountInfoAsync = async () => {
    try {
      this.setState({ loading: true });
      const response = await this.props.getAlpacaAccountInfo();

      if (
        response.status === types.ACTIVE ||
        response.status === types.APPROVED ||
        response.status === types.ACCOUNT_CLOSED
      ) {
        this.props.history.push("/");
        return;
      }

      let inputTypeItemsLength = 0;
      const inputTypeItems = [];
      const nonInputTypeItems = [];

      if (response.status === types.ACTION_REQUIRED) {
        const types = getRequiredKYCTypes(response.kyc_results || {})
          .map(([k, v]) => [k, v, k in typeScreens ? typeScreens[k] : null]);

        types.forEach(([actionType, , screenType]) => {
          if (
            screenType
            && !inputTypeItems.find(actionType => typeScreens[actionType] === screenType)
          ) {
            inputTypeItemsLength += 1;
            inputTypeItems.push(actionType);
          }
          else {
            nonInputTypeItems.push(actionType);
          }
        });
      }
      if (!inputTypeItemsLength) {
        this.props.history.push("/");
      }

      this.setState({
        loading: false,
        response,
        inputTypeItemsLength,
        actionItems: [...inputTypeItems, ...nonInputTypeItems],
      });
    }
    catch (error) {
      if (error.status === 403) {
        this.props.history.push("/");
      }
    }
  };

  handleChangeInput = (type, value) => {
    this.setState({
      [type]: value,
      error: null,
    });
  };

  onClickNextBtn = () => {
    this.setState(
      (prevState) => ({
        currentFormItem: prevState.currentFormItem + 1,
        isNextPreviousButtonDisabled: true,
      }),
      () => {
        setTimeout(() => {
          this.setState({
            isNextPreviousButtonDisabled: false,
          });
        }, 1000);
      }
    );
  };

  submit = async () => {
    const {
      identity_verification_front,
      identity_verification_back,
      tax_id_verification,
      address_verification,
      actionItems,
    } = this.state;

    const payload = [];
    const uploaded = [];
    const change = {};

    this.setState({ submitLoading: true });

    actionItems.map((actionItem) => {
      if (inputTypeActionItems.indexOf(actionItem) != -1) {
        switch (actionItem) {
          case types.IDENTITY_VERIFICATION:
          case types.INVALID_IDENTITY_PASSPORT:
            payload.push(identity_verification_front);
            payload.push(identity_verification_back);
            uploaded.push(actionItem);
            break;
          case types.DATE_OF_BIRTH:
            payload.push({
              ...identity_verification_front,
              document_type: "date_of_birth_verification",
            });
            payload.push({
              ...identity_verification_back,
              document_type: "date_of_birth_verification",
            });
            uploaded.push(actionItem);
            break;
          case types.TAX_IDENTIFICATION:
            payload.push(tax_id_verification);
            uploaded.push(actionItem);
            break;
          case types.ADDRESS_VERIFICATION:
            payload.push(address_verification);
            uploaded.push(actionItem);
            break;
        }
      }
    });

    if (this.state[VisaFields.VISA_TYPE] && this.state[VisaFields.VISA_EXPIRATION_DATE]) {
      change.identity = {
        visa_type: this.state[VisaFields.VISA_TYPE],
        visa_expiration_date: this.state[VisaFields.VISA_EXPIRATION_DATE],
      };
      uploaded.push(types.VISA_TYPE_OTHER);
    }

    try {
      await this.props.submitDocuments(payload);
      await response(brokerApi.submitKYC({
        change,
        uploaded,
      }));
      await this.getAlpacaAccountInfoAsync();

      this.setState({
        submitLoading: false,
        currentFormItem: this.state.currentFormItem + 1,
      });
    }
    catch (error) {
      this.setState({
        error: error && error.response ? error.response.body.message : "Error",
        submitLoading: false,
      });
    }
  };

  onClickPreviousBtn = () => {
    if (this.state.currentFormItem === 0) {
      return;
    }
    this.setState(
      (prevState) => ({
        currentFormItem: prevState.currentFormItem - 1,
        isNextPreviousButtonDisabled: true,
      }),
      () => {
        setTimeout(() => {
          this.setState({
            isNextPreviousButtonDisabled: false,
          });
        }, 1000);
      }
    );
  };

  getContentToDisplay = (data) => {
    const {
      isNextPreviousButtonDisabled,
      tax_id_verification,
      inputTypeItemsLength,
      currentFormItem,
      identity_verification_front,
      identity_verification_back,
      address_verification,
      submitLoading,
      error,
      actionItems,
      response,
    } = this.state;
    const content = [];

    if (data.status === types.REJECTED) {
      content.push(<Rejected key={types.REJECTED} />);

      return content;
    }

    if (data.status === types.APPROVAL_PENDING) {
      content.push(
        <ReviewInProgress
          key={types.APPROVAL_PENDING}
          slideName="ReviewInProgress"
        />
      );

      return content;
    }

    if (data.status === types.ACTION_REQUIRED) {
      actionItems
        .filter(type => type in typeScreens)
        .map(type => [type, typeScreens[type]])
        .reduce(
          (acc, current) =>
            acc.find(accVal => accVal[1] === current[1] && accVal[1] !== null)
              ? acc
              : [current, ...acc],
          [],
        )
        .map(([actionItem,], index) => {
          switch (actionItem) {
            case types.IDENTITY_VERIFICATION:
            case types.INVALID_IDENTITY_PASSPORT:
            case types.DATE_OF_BIRTH:
              content.push(
                <IdentityVerification
                  key={actionItem}
                  onClickNextBtn={this.onClickNextBtn}
                  onClickPreviousBtn={this.onClickPreviousBtn}
                  isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
                  isFirstItem={index === 0}
                  isLastItem={inputTypeItemsLength - 1 === currentFormItem}
                  inputTypeItemsLength={inputTypeItemsLength}
                  identity_verification_front={identity_verification_front}
                  identity_verification_back={identity_verification_back}
                  displayAdditional={actionItems.includes(types.INVALID_IDENTITY_PASSPORT)}
                  additionalInformation={response.kyc_results?.additional_information || ''}
                  handleChangeInput={this.handleChangeInput}
                  type={actionItem}
                  submit={this.submit}
                  isLoading={submitLoading}
                  error={error}
                  slideName="IdentityVerification"
                />
              );
              break;
            case types.VISA_TYPE_OTHER:
              content.push(
                <VisaVerification
                  key={actionItem}
                  onClickNextBtn={this.onClickNextBtn}
                  onClickPreviousBtn={this.onClickPreviousBtn}
                  isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
                  handleChangeInput={this.handleChangeInput}
                  visaType={this.state[VisaFields.VISA_TYPE]}
                  visaExpiration={this.state[VisaFields.VISA_EXPIRATION_DATE]}
                  isFirstItem={index === 0}
                  isLastItem={inputTypeItemsLength - 1 === currentFormItem}
                  inputTypeItemsLength={inputTypeItemsLength}
                  submit={this.submit}
                  isLoading={submitLoading}
                  error={error}
                  slideName="VisaVerification"
                />
              );
              break;
            case types.TAX_IDENTIFICATION:
              content.push(
                <SSNVerification
                  key={actionItem}
                  onClickNextBtn={this.onClickNextBtn}
                  onClickPreviousBtn={this.onClickPreviousBtn}
                  isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
                  handleChangeInput={this.handleChangeInput}
                  value={tax_id_verification}
                  isFirstItem={index === 0}
                  isLastItem={inputTypeItemsLength - 1 === currentFormItem}
                  inputTypeItemsLength={inputTypeItemsLength}
                  submit={this.submit}
                  isLoading={submitLoading}
                  error={error}
                  slideName="SSNVerification"
                />
              );
              break;
            case types.ADDRESS_VERIFICATION:
              content.push(
                <AddressVerification
                  key={actionItem}
                  onClickNextBtn={this.onClickNextBtn}
                  onClickPreviousBtn={this.onClickPreviousBtn}
                  isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
                  isFirstItem={index === 0}
                  isLastItem={inputTypeItemsLength - 1 === currentFormItem}
                  value={address_verification}
                  inputTypeItemsLength={inputTypeItemsLength}
                  submit={this.submit}
                  handleChangeInput={this.handleChangeInput}
                  isLoading={submitLoading}
                  error={error}
                  slideName="AddressVerification"
                />
              );
              break;
            case types.PEP:
            case types.FAMILY_MEMBER_PEP:
            case types.AFFILIATED:
            case types.CONTROL_PERSON:
              let ifAlreadyExist = content.filter(
                (c) => c.type.name === "DisclosureVerification"
              );

              if (ifAlreadyExist.length === 0) {
                content.push(
                  <DisclosureVerification
                    key={actionItem}
                    actionItems={actionItems}
                    slideName="DisclosureVerification"
                  />
                );
              }

              break;
            default:
              content.push(<ContactUs key={actionItem} slideName="ContactUs" />);
              break;
          }
        });

      if (inputTypeItemsLength === actionItems.length) {
        content.push(
          <ReviewInProgress
            key={types.APPROVAL_PENDING}
            slideName="ReviewInProgress"
          />
        );
      }
    }

    return content;
  };

  handleSlideChange = (index, item) => {
    if (item.props.slideName) {
      this.setSlideDynamicHeight(item.props.slideName);
      this.setState({
        currentFormItemName: item.props.slideName,
      });
    }
  };

  setSlideDynamicHeight = (slideName) => {
    if (slideName) {
      const slideElementHeight =
        document.getElementById(slideName).offsetHeight;
      const sliderContainer =
        document.getElementsByClassName("slider-wrapper")[0];

      if (slideName === "Confirmation") {
        sliderContainer.style.height = "unset";
      } else {
        sliderContainer.style.height = `${slideElementHeight + 20}px`;
      }
    }
  };

  render() {
    const { loading, currentFormItem } = this.state;
    const { logo, displayName } = this.context;
    return (
      <div>
        <div className="eko-getStarted onboarding-alpaca" />

        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.topSection}>
            </div>
            <Carousel
              selectedItem={currentFormItem}
              showStatus={false}
              showIndicators={false}
              axis="horizontal"
              showThumbs={false}
              swipeable={false}
              transitionTime={250}
              width="100vw"
              showArrows={false}
              useKeyboardArrows={false}
              onChange={this.handleSlideChange}
            >
              {this.state.response &&
                this.getContentToDisplay(this.state.response)}
            </Carousel>
          </>
        )}
      </div>
    );
  }
}

function IdentityVerification({
  onClickNextBtn,
  isNextPreviousButtonDisabled,
  onClickPreviousBtn,
  isFirstItem,
  identity_verification_front,
  identity_verification_back,
  handleChangeInput,
  displayAdditional,
  additionalInformation,
  isLastItem,
  submit,
  isLoading,
  error,
  slideName,
}) {
  const [invalidFront, setInvalidFront] = useState(null);
  const [invalidBack, setInvalidBack] = useState(null);
  const displayName = useContext(ThemeContext).displayName;

  useEffect(() => {
    if (identity_verification_front) {
      setInvalidFront(false);
    }
  }, [identity_verification_front]);

  useEffect(() => {
    if (identity_verification_back) {
      setInvalidBack(false);
    }
  }, [identity_verification_back]);

  const handleClickNextBtn = () => {
    if (identity_verification_front && identity_verification_back) {
      if (isLastItem) {
        submit();
      } else {
        onClickNextBtn();
      }
    } else {
      setInvalidFront(!identity_verification_front);
      setInvalidBack(!identity_verification_back);
    }
  };

  return (
    <div id={slideName} className={styles.kycOuterContainer}>
      <h1 className={styles.kycTitle}>Identity verification</h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}. We need additional
        information to verify your identity before approving your account.
      </div>

      <div className={styles.kycSubText}>
        This may be required due to a discrepancy between your legal name and
        what's been provided, or our clearing firm not being able to verify your
        data. Once you provide additional documents we will process your
        application again.
      </div>
      <br />
      <br />

      <div className={styles.kycText}>Please verify your identity</div>

      <div className={styles.kycSubText}>
        {displayAdditional && !!additionalInformation.length
          ? additionalInformation
          : (
            <>
              To verify your identity, we need both front and back scans of your
              government issued photo ID. This can be your driver’s license, state
              issued ID, DoD, ID Card or passport.
            </>
          )
        }
      </div>

      <SlideContainerKYC
        isFirstItem={isFirstItem}
        onClickNextBtn={handleClickNextBtn}
        isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
        onClickPreviousBtn={onClickPreviousBtn}
        isLastItem={isLastItem}
        isLoading={isLoading}
      >
        <UploadInput
          label="Attach your Photo ID front"
          onChange={(value) => {
            if (value) {
              const { base64, type } = value;

              handleChangeInput("identity_verification_front", {
                document_type: "identity_verification",
                content: base64,
                mime_type: type,
                document_sub_type: "front",
              });
            } else {
              handleChangeInput("identity_verification_front", value);
            }
          }}
        />
        {invalidFront && <div className={styles.inputErrorMsg}>Required</div>}

        <br />
        <br />
        <UploadInput
          label="Attach your Photo ID back"
          onChange={(value) => {
            if (value) {
              const { base64, type } = value;
              handleChangeInput("identity_verification_back", {
                document_type: "identity_verification",
                content: base64,
                mime_type: type,
                document_sub_type: "back",
              });
            } else {
              handleChangeInput("identity_verification_back", value);
            }
          }}
        />
        {invalidBack && <div className={styles.inputErrorMsg}>Required</div>}
        {error && <div className={styles.error}>{error}</div>}
      </SlideContainerKYC>
    </div>
  );
}

function SSNVerification({
  onClickNextBtn,
  isNextPreviousButtonDisabled,
  onClickPreviousBtn,
  handleChangeInput,
  value,
  isFirstItem,
  isLastItem,
  submit,
  isLoading,
  error,
  slideName,
}) {
  const [invalid, setInvalid] = useState(false);
  const displayName = useContext(ThemeContext).displayName;

  useEffect(() => {
    if (value) {
      setInvalid(false);
    }
  }, [value]);

  const handleClickNextBtn = () => {
    if (value) {
      if (isLastItem) {
        submit();
      } else {
        onClickNextBtn();
      }
    } else {
      setInvalid(true);
    }
  };

  return (
    <div id={slideName} className={styles.kycOuterContainer}>
      <h1 className={styles.kycTitle}>SSN verification</h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}. We need additional
        information to verify your identity before approving your account.
      </div>

      <div className={styles.kycSubText}>
        This may be required due to a discrepancy between your legal name and
        what's been provided, or our clearing firm not being able to verify your
        data. Once you provide additional documents we will process your
        application again.
      </div>
      <br />
      <br />

      <div className={styles.kycText}>Please verify your SSN</div>
      <div className={styles.kycSubText}>
        To verify your identity, we need a scan of your Social Security Card.
      </div>
      <SlideContainerKYC
        isFirstItem={isFirstItem}
        onClickNextBtn={handleClickNextBtn}
        isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
        onClickPreviousBtn={onClickPreviousBtn}
        isLastItem={isLastItem}
        isLoading={isLoading}
      >
        <UploadInput
          label="Attach your Social Security Card Front"
          onChange={(value) => {
            if (value) {
              const { base64, type } = value;
              handleChangeInput("tax_id_verification", {
                document_type: "tax_id_verification",
                content: base64,
                mime_type: type,
              });
            } else {
              handleChangeInput("tax_id_verification", value);
            }
          }}
        />
        {invalid && <div className={styles.inputErrorMsg}>Required</div>}
        {error && <div className={styles.error}>{error}</div>}
      </SlideContainerKYC>
    </div>
  );
}

function AddressVerification({
  onClickNextBtn,
  isNextPreviousButtonDisabled,
  onClickPreviousBtn,
  isFirstItem,
  isLastItem,
  submit,
  value,
  handleChangeInput,
  isLoading,
  error,
  slideName,
}) {
  const [invalid, setInvalid] = useState(false);
  const displayName = useContext(ThemeContext).displayName;

  useEffect(() => {
    if (value) {
      setInvalid(false);
    }
  }, [value]);

  const handleClickNextBtn = () => {
    if (value) {
      if (isLastItem) {
        submit();
      } else {
        onClickNextBtn();
      }
    } else {
      setInvalid(true);
    }
  };
  return (
    <div id={slideName} className={styles.kycOuterContainer}>
      <h1 className={styles.kycTitle}>Address verification</h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}. We need additional
        information to verify your identity before approving your account.
      </div>

      <div className={styles.kycSubText}>
        This may be required due to a discrepancy between your legal name and
        what's been provided, or our clearing firm not being able to verify your
        data. Once you provide additional documents we will process your
        application again.
      </div>
      <br />
      <br />

      <div className={styles.kycText}>Please verify your address</div>
      <div className={styles.kycSubText}>
        To verify your identity, we need a verification of your address. This
        can be a lease, bank statement or utility bill from the last 30 days
        displaying your full name and address.
      </div>
      <SlideContainerKYC
        onClickNextBtn={handleClickNextBtn}
        isNextPreviousButtonDisabled={isNextPreviousButtonDisabled}
        onClickPreviousBtn={onClickPreviousBtn}
        isFirstItem={isFirstItem}
        isLastItem={isLastItem}
        isLoading={isLoading}
      >
        <UploadInput
          label="Attach your proof of address"
          onChange={(value) => {
            if (value) {
              const { base64, type } = value;
              handleChangeInput("address_verification", {
                document_type: "address_verification",
                content: base64,
                mime_type: type,
              });
            } else {
              handleChangeInput("address_verification", value);
            }
          }}
        />
        {invalid && <div className={styles.inputErrorMsg}>Required</div>}
        {error && <div className={styles.error}>{error}</div>}
      </SlideContainerKYC>
    </div>
  );
}

function DisclosureVerification({ slideName, actionItems }) {
  const displayName = useContext(ThemeContext).displayName;
  const renderText = (type) => {
    switch (type) {
      case "AFFILIATED":
        return (
          <>
            <div className={styles.kycText}>
              For FINRA or a US registered broker-dealers you or an immediate
              family member are affiliated with or work for:
              <span className={styles.kycSubText}>
                {" "}
                company name, company address, company email, company
                registration number
              </span>
            </div>
            <div className={styles.kycSubText}>
              Please also provide a Rule 407 letter written and signed by a
              compliance officer on company letterhead explicitly granting you
              permission to carry the account and explicitly stating whether or
              not the firm requires access to duplicate account statements
              and/or trade confirmations. If the firm would like online access
              to your duplicate account statements and/or trade confirmations,
              include in the letter the applicant's full name and the following
              information for the interested party: first and last name, title,
              email address, telephone number.
            </div>
          </>
        );
      case "PEP":
        return (
          <>
            <div className={styles.kycText}>
              As a Politically Exposed Person:
              <span className={styles.kycSubText}>
                {" "}
                Your job title / occupation and address
              </span>
            </div>
          </>
        );
      case "FAMILY_MEMBER_PEP":
        return (
          <>
            <div className={styles.kycText}>
              For Politically Exposed Persons you are a family member of:
              <span className={styles.kycSubText}>
                {" "}
                Politically exposed person's name, job title / occupation and
                address
              </span>
            </div>
          </>
        );
      case "CONTROL_PERSON":
        return (
          <>
            <div className={styles.kycText}>
              For publicly traded companies where you or an immediate family
              member are an officer or 10% or greater shareholder
              <span className={styles.kycSubText}>
                {" "}
                Company name, company ticker, company address and company email
              </span>
            </div>
            <div className={styles.kycSubText}>
              Please also provide a Rule 407 letter written and signed by a
              compliance officer on company letterhead explicitly granting you
              permission to carry the account and explicitly stating whether or
              not the firm requires access to duplicate account statements
              and/or trade confirmations. If the firm would like online access
              to your duplicate account statements and/or trade confirmations,
              include in the letter the applicant's full name and the following
              information for the interested party: first and last name, title,
              email address, telephone number.
            </div>
          </>
        );
    }
  };
  return (
    <div
      id={slideName}
      className={styles.kycOuterContainer}
      style={{ paddingBottom: 0 }}
    >
      <h1 className={styles.kycTitle}>Disclosure verification</h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}. We need additional
        information to verify your identity before approving your account.
      </div>

      <div className={styles.kycSubText}>
        Please reach out to{" "}
        <a href="mailto:hello@eko.investments" className={styles.ekoEmail}>
          hello@eko.investments
        </a>{" "}
        and provide:
      </div>
      {actionItems.map((type) => (
        <>{renderText(type)}</>
      ))}
    </div>
  );
}

function ContactUs({ slideName }) {
  const displayName = useContext(ThemeContext).displayName;
  return (
    <div
      id={slideName}
      className={styles.kycOuterContainer}
      style={{ paddingBottom: 0 }}
    >
      <h1 className={styles.kycTitle}>Please contact us</h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}. We need additional
        information to verify your identity before approving your account.
      </div>

      <div className={styles.kycSubText}>
        Please reach out to{" "}
        <a href="mailto:hello@eko.investments" className={styles.ekoEmail}>
          hello@eko.investments
        </a>{" "}
        for more details.
      </div>
    </div>
  );
}

function ReviewInProgress({ slideName }) {
  const displayName = useContext(ThemeContext).displayName;
  return (
    <div
      id={slideName}
      className={styles.kycOuterContainer}
      style={{ paddingBottom: 0 }}
    >
      <h1 className={styles.kycTitle}>Review in progress</h1>

      <div className={styles.kycText}>
        Your application is pending. We will let you know once it's completed!
      </div>

      <div className={styles.kycSubText}>
        This process usually takes less than 15 minutes, but can take up to 2
        days if manual review is needed.
      </div>
    </div>
  );
}

function Rejected() {
  const displayName = useContext(ThemeContext).displayName;
  return (
    <div className={styles.kycOuterContainer}>
      <h1 className={styles.kycTitle}>Application rejected</h1>

      <div className={styles.kycText}>
        Thank you for applying for investing with {displayName}.
      </div>

      <div className={styles.kycSubText}>
        Unfortunately, your application was rejected. Please reach out to{" "}
        <a href="mailto:hello@eko.investments" className={styles.ekoEmail}>
          hello@eko.investments
        </a>{" "}
        for more information.
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getAlpacaAccountInfo: () => dispatch(alpacaAccountInfo()),
  submitDocuments: (payload) => dispatch(submitDocuments(payload)),
});

export default connect(null, mapDispatchToProps)(KYC);
