import React from 'react';
import { ListProps } from 'antd';

import * as Styled from './List.organism.styled';

export const List = <T extends unknown>(props: ListProps<T> & {EmptyValue?: React.ReactNode}) => {
  if ((!props.dataSource || props.dataSource.length === 0) && (props.EmptyValue !== undefined)) {
    return <>{props.EmptyValue}</>;
  }
  return <Styled.Container<any> {...props} />;
};
