import React, { FC, useMemo } from 'react';
import { Collapse, CollapseProps } from 'antd';

import * as S from './Accordion.styled';

export type Props = Omit<
CollapseProps,
| 'accordion'
| 'ghost'
| 'expandIconPosition'
| 'expandIcon'
| 'children'
> & {
  items: {
    key: string | number,
    label: React.ReactNode,
    children: React.ReactNode
  }[]
};

export const Accordion: FC<Props> = ({ items, ...props }) => {
  const children = useMemo(() => (
    items.map(item => (
      <Collapse.Panel header={item.label} key={item.key}>{item.children}</Collapse.Panel>
    ))
  ), [items]);

  return (
    <>
      <S.GlobalStyles/>
      <S.Container {...props}>{children}</S.Container>
    </>
  );
};
