import loadable from '@loadable/component';
import { PageLayout } from '~/ui/layouts/Page';
import { useSelector } from 'react-redux';
import { selectProfile } from '@store/profile/selectors';

import { Skeleton } from './components/Skeleton';

type Props = {
  className?: string;
}

const FundWithdrawPageContent = loadable(() => import('./FundWithdrawPage'), { fallback: Skeleton });

export const FundWithdrawPageWrapper: Props = (props) => {
  const profile = useSelector(selectProfile);
  return (
    <PageLayout
      className={props.className}
      title="Fund / Withdraw"
      showFundBtn={!profile.has_alpaca_account}
    >
      <FundWithdrawPageContent />
    </PageLayout>
  );
};
